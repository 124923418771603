import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { alertActions } from "../../../actions";
import { UserService } from "../../../services";

const useImageListHook = () => {
  const [page, setPage] = useState(1);
  const [category, setCategory] = useState("image");
  const [listRes, setListRes] = useState({});
  const [search, setSearch] = useState("");
  const [media, setMedia] = useState("image");
  const [loading, setLoading] = useState("loading");
  const [types, seTtypes] = useState("");
  const [cart, setCart] = useState();
  const [cartDetail, setCartDetail] = useState({});
  let history = useNavigate();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  var tax;
  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    var footerClass = document.getElementById("footer-sec");
    footerClass.classList.remove("hide");
    setShowModal(false);
  };
  // const typesss = "Black and White";
  useEffect(() => {
    listDisplay(media, search, page, types);
  }, [cart]);
  //api call for list
  const listDisplay = (category, search, page, types) => {
    UserService.imageDocs(page, category, search, types)
      .then((response) => {
        setListRes(response);
        setLoading("notempty");
        seTtypes(types);
        setPage(page);
        tax = response.tax;
      })
      .catch((error) => {
        console.log("Errorreg", error);
        setLoading("empty");
      });
  };
  //select media types
  function changeMediaHandler(e) {

    if (e.target.name === "audio") {
      setCategory("audio");
      listDisplay("audio", search, 1, "");
      setMedia(e.target.value);
    } else if (e.target.name === "video") {
      setCategory("video");
      listDisplay("video", search, 1, "");
      setMedia(e.target.value);
    } else if (e.target.name === "image") {
      setCategory("image");
      listDisplay("image", search, 1, types);
      setMedia(e.target.value);
    }
  }
  //search
  function changeSearchHandler(e) {
    setSearch(e.target.value);
    setCategory(category);
    listDisplay(category, e.target.value, page, types);
  }

  //pagination
  const changeCurrentPage = (page) => {
    setPage(page.selected + 1);

    listDisplay(category, search, page.selected + 1, types);
  };
  //get cart
  function handleGetCart() {
    UserService.getCart()
      .then((response) => {
        setCart(response?.data?.data?.count);
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  }
  //add cart
  function handleAddCart(product_id, category, quantity, amount) {

    UserService.addCart(product_id, category, quantity, amount)
      .then((response) => {
        if (response?.data?.status.code === 0) {
          handleGetCart();
          dispatch(alertActions.success(response?.data?.status?.message));
        } else {
          dispatch(alertActions.error(response?.data?.status?.message));
        }
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  }
  //handleModal
  function handleModal(id, category) {

    UserService.cartDetail(id, category)
      .then((response) => {
        console.log(response)

        setCartDetail(response);
        setShowModal(true);

      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  }
  //remove Cart
  function handleRemoveCart(id) {
    UserService.removeCart(id)
      .then((response) => {
        if (response?.data?.status?.code === 0) {
          handleGetCart();
          dispatch(alertActions.success(response?.data?.status?.message));
        } else {
          dispatch(alertActions.error(response?.data?.status?.message));
        }
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  }

  //reset filter

  return {
    listRes,
    changeMediaHandler,
    media,
    changeSearchHandler,

    search,
    changeCurrentPage,
    page,
    loading,
    types,
    listDisplay,
    category,
    handleRemoveCart,
    cart,
    handleAddCart,
    showModal,
    closeModal,
    handleModal,
    cartDetail,
    setSearch,
    tax
  };
};
export default useImageListHook;
