import Header from "../RRCHeader";
import noImage from "../../../assets/images/defaultimg.png";
import "./checkoutpage.css";
import { useLocation, useNavigate } from "react-router";
import useCheckout from "./useCheckout";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { alertActions, userLogout } from "../../../actions";
import { commaValidation } from "../../../utility";
import noAudio from "../../../assets/images/audio-default.png";
import noVideo from "../../../assets/images/defaultvideo.png";
import audioImgPopUp from "../../../assets/images/aud.png";

const Checkout = (tokenExpired) => {
  const location = useLocation();
  const name = useSelector((state) => state?.userData?.user?.name);
  const mobile = useSelector((state) => state?.userData?.user?.mobile);
  const email = useSelector((state) => state?.userData?.user?.email);
  const {
    handleCheckoutItem,
    cart,
    changeCtypeHandler,
    purpose,
    cartData,
    totalcount,
    amount,
    gstamount,
    totalPrice,
  } = useCheckout();
  const dispatch = useDispatch();
  var singleProduct = [];
  let history = useNavigate();
  const type = location?.state?.type;
  console.log("ll", location?.state);
  console.log("singleProduct", singleProduct);
  useEffect(() => {
    if (tokenExpired.tokenExpired) {
      setTimeout(() => {
        dispatch(alertActions.error("Authentication error..Please login"));
      }, 50);

      dispatch(userLogout());
      history("/login");
    }
  }, [tokenExpired.tokenExpired]);

  return (
    <div className="main-page flt">
      <Header cart={cart} />
      <div className="chcekout-maindiv flt">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="chckot-maintxt-orders flt">
                <div className="chckot flt"> Checkout </div>
                <div className="order-detail-main-sec">
                  <div className="sub-sec-div">
                    <div className="left-div">
                      <p className="order-haed">Order Details</p>

                      <div className="flt">
                        {cartData &&
                          cartData.result &&
                          cartData.result.map((res) => (
                            <div className="order-details-div">
                              {/* image */}
                              {res?.category && res?.category === "image" && (
                                <div className={`order-details-image`}>
                                  {res?.image[0]?.url ? (
                                    <img
                                      src={`${process.env.REACT_APP_MEDIA_DOMAIN}${res?.image[0]?.url}`}
                                    />
                                  ) : (
                                    <img src={`${noImage}`} />
                                  )}
                                </div>
                              )}
                              {/* audio */}
                              {res?.category && res?.category === "audio" && (
                                <div className={`order-details-image`}>
                                  {res?.audio[0]?.url ? (
                                    <>
                                      <img
                                        className="table-image"
                                        src={audioImgPopUp}
                                      />
                                      <audio controls className="audio">
                                        <source
                                          src={`${process.env.REACT_APP_MEDIA_DOMAIN}${res?.audio[0]?.url}`}
                                          type="audio/mpeg"
                                        />
                                      </audio>
                                    </>
                                  ) : (
                                    <img src={`${noAudio}`} />
                                  )}
                                </div>
                              )}
                              {/* video */}

                              {res?.category && res?.category === "video" && (
                                <div className={`order-details-image`}>
                                  {res?.video[0]?.url ? (
                                    <video
                                      width="100"
                                      className="video-tag"
                                      controls
                                    >
                                      <source
                                        src={`${process.env.REACT_APP_MEDIA_DOMAIN}${res?.video[0]?.url}`}
                                        type="video/mp4"
                                      />
                                    </video>
                                  ) : (
                                    <img src={`${noVideo}`} />
                                  )}
                                </div>
                              )}
                              {/* image */}
                              {res?.category && res?.category === "image" && (
                                <div className="order-details-data">
                                  <p className="order-details-prod-name">
                                    {res?.image[0]?.itemName}
                                    <span className="order-details-prod-count">
                                      ({res?.image[0]?.numberOfDocs}{" "}
                                      {res?.image[0]?.mediatype})
                                    </span>
                                  </p>
                                  <p className="order-item-price">
                                    &#8377;
                                    {commaValidation(res?.image[0]?.price)}
                                    <div className="displayproperty">
                                      {singleProduct.push(
                                        parseFloat(res?.image[0]?.price) +
                                          (parseFloat(gstamount) *
                                            parseFloat(res?.image[0]?.price)) /
                                            100
                                      )}
                                    </div>
                                  </p>
                                </div>
                              )}

                              {/* audio */}
                              {res?.category && res?.category === "audio" && (
                                <div className="order-details-data">
                                  <p className="order-details-prod-name">
                                    {res?.audio[0]?.itemName}
                                    <span className="order-details-prod-count">
                                      ({res?.audio[0]?.numberOfDocs}{" "}
                                      {res?.audio[0]?.mediatype})
                                    </span>
                                  </p>
                                  <p className="order-item-price">
                                    &#8377;
                                    {commaValidation(res?.audio[0]?.price)}
                                    {/* {singleProduct.push(res?.audio[0]?.price)} */}
                                    <div className="displayproperty">
                                      {singleProduct.push(
                                        parseFloat(res?.audio[0]?.price) +
                                          (parseFloat(gstamount) *
                                            parseFloat(res?.audio[0]?.price)) /
                                            100
                                      )}
                                    </div>
                                  </p>
                                </div>
                              )}

                              {/* video */}
                              {res?.category && res?.category === "video" && (
                                <div className="order-details-data">
                                  <p className="order-details-prod-name">
                                    {res?.video[0]?.itemName}
                                    <span className="order-details-prod-count">
                                      ({res?.video[0]?.numberOfDocs}{" "}
                                      {res?.video[0]?.mediatype})
                                    </span>
                                  </p>
                                  <p className="order-item-price">
                                    &#8377;
                                    {commaValidation(res?.video[0]?.price)}
                                    {/* {singleProduct.push(res?.video[0]?.price)}
                                     */}
                                    <div className="displayproperty">
                                      {singleProduct.push(
                                        parseFloat(res?.video[0]?.price) +
                                          (parseFloat(gstamount) *
                                            parseFloat(res?.video[0]?.price)) /
                                            100
                                      )}
                                    </div>
                                  </p>
                                </div>
                              )}

                              <div className="order-details-divider">
                                <hr />
                              </div>
                            </div>
                          ))}
                        {/* {cartData && cartData.result && cartData.result.map((res) =>
                          <div className={`order-details-image ${type}`}>
                            {res?.category && res?.category === "audio" ? (
                              <>
                                <img className='table-image' src={audioImgPopUp} />
                                <audio controls className="audio">
                                  <source
                                    src={`${process.env.REACT_APP_MEDIA_DOMAIN}${location?.state?.url}`}
                                    type="audio/mpeg"
                                  />
                                </audio>
                              </>
                            ) : (
                              <img src={`${noAudio}`} />
                            )}
                          </div>
                        )} */}
                        {/* {cartData && cartData.result && cartData.result.map((res) =>
                          <div className={`order-details-image ${type}`}>
                            {res?.category && res?.category === "video" ? (
                              <video width="100" height="100" className="video-tag" controls>
                                <source
                                  src={`${process.env.REACT_APP_MEDIA_DOMAIN}${location?.state?.url}`}
                                  type="video/mp4"
                                />
                              </video>
                            ) : (
                              <img src={`${noVideo}`} />
                            )}
                          </div>
                        )} */}
                      </div>

                      {/* <div className="order-calc-sec">
                      <div className="order-calc-det">
                        <p className="order-calc-head">Price</p>
                        <p className="order-calc-cont">
                          &#8377;{location?.state?.price}
                        </p>
                      </div>
                      <div className="order-calc-det">
                        <p className="order-calc-head">Shipping Charges</p>
                        <p className="order-calc-cont">&#8377;50</p>
                      </div>
                      <div className="order-calc-det">
                        <p className="order-calc-head">Tax</p>
                        <p className="order-calc-cont">&#8377;30</p>
                      </div>
                    </div> */}

                      <div className="order-calc-tot">
                        <div className="order-calc-det flt">
                          <p className="order-calc-tot-head1">Items</p>

                          <p className="order-calc-tot-cont1">{totalcount}</p>
                        </div>
                        <div className="order-calc-det flt">
                          <p className="order-calc-tot-head1">Price</p>

                          <p className="order-calc-tot-cont1">
                            ₹ &nbsp; {commaValidation(amount)}
                          </p>
                        </div>
                        {/* <div className="order-calc-det flt">
                          <p className="order-calc-tot-head1">Shipping Charges</p>

                          <p className="order-calc-tot-cont1">
                            &#8377;{commaValidation(location?.state?.price)}
                          </p>
                        </div> */}
                        <div className="order-calc-det flt">
                          <p className="order-calc-tot-head1">
                            GST {gstamount}%
                          </p>

                          <p className="order-calc-tot-cont1">
                            ₹ &nbsp;
                            {commaValidation(
                              (parseFloat(gstamount) * parseFloat(amount)) / 100
                            )}
                          </p>
                        </div>
                        <hr />
                        <div className="order-calc-det flt">
                          <p className="order-calc-tot-head">Total Amount</p>

                          <p className="order-calc-tot-cont">
                            ₹&nbsp;
                            {commaValidation(
                              parseFloat(amount) +
                                (parseFloat(gstamount) * parseFloat(amount)) /
                                  100
                            )}
                          </p>
                        </div>
                        <hr />
                      </div>
                    </div>
                    {console.log(singleProduct + "kl")}
                    <div className="right-div flt">
                      <p className="order-haed-billing">Billing Information</p>
                      <div className="cust-info-sec">
                        <div className="cust-info-det">
                          <p className="cust-info-head">Name</p>
                          <p className="cust-info-head-sep">:</p>
                          <p className="cust-info-cont">{name ? name : ""}</p>
                        </div>
                        <div className="cust-info-det">
                          <p className="cust-info-head">Phone Number</p>
                          <p className="cust-info-head-sep">:</p>
                          <p className="cust-info-cont">
                            +91 {mobile ? mobile : ""}
                          </p>
                        </div>
                        <div className="cust-info-det">
                          <p className="cust-info-head">Email</p>
                          <p className="cust-info-head-sep">:</p>
                          <p className="cust-info-cont email_text">
                            {email ? email : ""}
                          </p>
                        </div>
                      </div>
                      <div className="purpose flt">
                        <div className="purposedata flt">
                          <div className="selectpurpose">Select Purpose</div>
                          <div className="cover_purpose">
                            <span>
                              <input
                                type="radio"
                                id="general"
                                value="General purpose"
                                name="gender"
                                onClick={changeCtypeHandler}
                              />{" "}
                              <label for="general">General Purpose</label>
                            </span>
                            <span>
                              <input
                                type="radio"
                                id="research"
                                value="Research purpose"
                                name="gender"
                                onClick={changeCtypeHandler}
                              />{" "}
                              <label for="research">Research Purpose</label>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pay-btn-sec">
                    <button
                      className="pay-button"
                      onClick={() =>
                        handleCheckoutItem(
                          // location?.state?.id,
                          // location?.state?.quantity
                          singleProduct
                        )
                      }
                    >
                      Pay
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Checkout;
