import { useEffect, useState } from "react";
import { alertActions } from "../../../actions/alert.actions";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { UserService } from "../../../services";

const useConfirmPasswordHook = () => {
  let history = useNavigate();
  const { id } = useParams();
  const [new_password, setNew_password] = useState("");
  const [re_password, setRe_password] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [getConfirmData, setConfirmData] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    if (getConfirmData?.data?.status?.code === 0) {
      setNew_password("");
      setRe_password("");
      history("/login");
      setSubmitted(false);
      setTimeout(() => {
        dispatch(alertActions.success(getConfirmData?.data?.status?.message));
      }, 100);
    } else {
      dispatch(alertActions.error(getConfirmData?.data?.status?.message));
    }
  }, [getConfirmData]);

  function handleRePasswordChange(e) {
    setRe_password(e.target.value);
  }
  function handleNewPasswordChange(e) {
    setNew_password(e.target.value);
  }

  //handle submit for confirm password
  function handleSubmit(e) {
    e.preventDefault();
    setSubmitted(true);

    if (new_password && re_password) {
      if (new_password !== re_password) {
        dispatch(alertActions.error("Both Password Must Match"));
      } else {
        UserService.confirmPassword(new_password, id)
          .then((response) => {
            setConfirmData(response);
          })
          .catch((error) => {
            console.log("Errorreg", error);
          });
      }
    }
  }

  return {
    new_password,
    re_password,
    submitted,
    handleNewPasswordChange,
    handleRePasswordChange,
    handleSubmit,
  };
};
export default useConfirmPasswordHook;
