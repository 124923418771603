import useForgotPasswordHook from "./useForgotPasswordHook";
import Logo from "../../../assets/images/logo.png";
import "./ForgotPassword.css";
import Header from "../RRCHeader";
const ForgotPassword = () => {
  const { mobile, submitted, handleChange, handleSubmit } =
    useForgotPasswordHook();
  const forgot = () => {
    return (
      <div>
        <Header />
        <div className="login_container flt">
          <div className="login_box">
            <div className="login_cont flt">
              <div style={{ padding: "2% 15%" }}>
                <img src={Logo} style={{ width: "100%" }} />
              </div>
              <div>
                <p className="sec-header">FORGOT YOUR PASSWORD?</p>
                <p className="sec-sub-header">
                  Please enter the email you’d like your otp to be sent
                </p>
              </div>

              <div className="form-group flt">
                <input
                  type="number"
                  placeholder="Enter Mobile Number"
                  name="mobile"
                  value={mobile}
                  autoComplete="off"
                  onChange={handleChange}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit(e);
                    }
                  }}
                  className={
                    submitted && !mobile ? "input-sec is-invalid" : "input-sec"
                  }
                />
                {submitted && !mobile && (
                  <div className="invalid-feedback">
                    Mobile number is required
                  </div>
                )}
              </div>

              <button className="login_btn flt" onClick={handleSubmit}>
                Submit
              </button>

              <div className="reg-sec">
                <p className="reg-sec-content">
                  ALREADY A MEMBER ? <a href="/login">LOGIN HERE</a>
                </p>
              </div>
            </div>
            <div className="image-sec-right">
              <div className="right-sec">
                <p className="right-sec-header">Welcome to RRC</p>
                <p className="right-sec-desc">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Mattis molestie amet blandit nisi. Morbi faucibus ut id tortor
                  semper eLorem ipsum dolor sit amet, consectetur adipiscing
                  elit. Mattis molestie amet blandit nisi. Morbi faucibus ut id
                  tortor semper e
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return <div>{forgot()}</div>;
};
export default ForgotPassword;
