import { Link } from "react-router-dom";
import rrclogo from "../../../assets/images/rrclogo.png";
import cart from "../../../assets/images/cart.png";
import "../../pages/RrcHomeScreen/home.css";
import { alertActions, userLogout } from "../../../actions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { UserService } from "../../../services";
import profimg from "../../../assets/images/myprof.png";

const Header = ({ cart: propCart }) => {
  const user = JSON.parse(localStorage.getItem("state"));
  const isUser = user ? Object.keys(user.userData).length : 0;
  let history = useNavigate();
  const dispatch = useDispatch();
  const [cartCount, setCartCount] = useState();
  const handleLogout = () => {
    dispatch(userLogout());
    history("/");
    dispatch(alertActions.success("Logout Success"));
  };
  const pathname = window.location.pathname;

  const gotoMyProfile = () => {
    history("/MyAccount");
  };
  useEffect(() => {
    UserService.getCart()
      .then((response) => {
        setCartCount(response?.data?.data?.count);
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  }, [propCart]);

  const openNav = () => {
    document.getElementById("mySidenav").style.width = "100%";
  };

  const closeNav = () => {
    document.getElementById("mySidenav").style.width = "0";
  };

  return (
    <div className="container_header_main flt">
      <div className="menu-main flt">
        <div className="row header">
          <div className="col-sm-2">
            <a href="/" className="header-logo">
              <img src={rrclogo} />
            </a>
          </div>
          <div className="col-sm-7">
            <div className="header-cont">
              <Link to="/" className="home-menu">
                HOME
              </Link>
              <Link className="home-menu" to="/gallery">
                GALLERY
              </Link>
              <Link className="home-menu" to="/list">
                DETAILS LIST
              </Link>
              {/* <Link className='home-menu' to='/#'>
              FOOTAGE
            </Link>
            <Link className='home-menu' to='/#'>
              MUSIC
            </Link> */}
              <Link className="home-menu" to="/blogs">
                BLOG
              </Link>
              <Link className="home-menu" to="/events">
                EVENTS
              </Link>
              {/* <Link className='home-menu' to='/#'>
              PUBLICATIONS
            </Link> */}
              <Link className="home-menu" to="/contact">
                CONTACT
              </Link>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="header-right">
              {propCart || propCart === 0 || cartCount || cartCount == 0 ? (
                <a href="/getCart">
                  {propCart == 0 || cartCount == 0 ? (
                    <span className="cart-sec">
                      {" "}
                      <img src={cart} />{" "}
                    </span>
                  ) : (
                    <span className="cart-sec">
                      {/* {propCart === 0 || cartCount === 0 && (
                    <img src={cart} />
                  )} */}
                      <img src={cart} />{" "}
                      <span className="cart-count">
                        {propCart ? propCart : cartCount}
                      </span>
                    </span>
                  )}
                </a>
              ) : (
                <a href="/login">
                  <span className="cart-sec">
                    <img src={cart} />
                  </span>
                </a>
              )}

              <div className="logn-bttn flt">
                {isUser ? (
                  <a className="btton-log" onClick={handleLogout}>
                    LOG OUT
                  </a>
                ) : (
                  <a className="btton-log" href="/login">
                    LOG IN
                  </a>
                )}
              </div>
              {isUser ? (
                <div className="logn-bttns flt" onClick={gotoMyProfile}>
                  <img src={profimg} />
                </div>
              ) : (
                ""
              )}

              {!isUser && (
                <div className="sinup-btton flt">
                  <a className="btton-sign" href="/registration">
                    SIGN UP
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="responsive-header">
          <div id="mySidenav" className="sidenav">
            <a className="closebtn" onClick={() => closeNav()}>
              &times;
            </a>
            <Link to="/" className="home-menu">
              HOME
            </Link>
            <Link className="home-menu" to="/gallery">
              GALLERY
            </Link>
            <Link className="home-menu" to="/list">
              DETAILS LIST
            </Link>
            {/* <Link className='home-menu' to='/#'>
            FOOTAGE
          </Link>
          <Link className='home-menu' to='/#'>
            MUSIC
          </Link> */}
            <Link className="home-menu" to="/blogs">
              BLOG
            </Link>
            {isUser ? (
              <Link className="home-menu" to="/MyAccount">
                ACCOUNT DETAILS
              </Link>
            ) : (
              ""
            )}
            <Link className="home-menu" to="/events">
              EVENTS
            </Link>
            {/* <Link className='home-menu' to='/#'>
            PUBLICATIONS
          </Link> */}
            <Link className="home-menu" to="/contact">
              CONTACT
            </Link>
            <>
              {propCart || propCart === 0 || cartCount || cartCount == 0 ? (
                <a href="/getCart">
                  {propCart == 0 || cartCount == 0 ? (
                    <span className="cart-sec">
                      {" "}
                      <img src={cart} />{" "}
                    </span>
                  ) : (
                    <span className="cart-sec">
                      {/* {propCart === 0 || cartCount === 0 && (
                    <img src={cart} />
                  )} */}
                      <img src={cart} />{" "}
                      <span className="cart-count">
                        {propCart ? propCart : cartCount}
                      </span>
                    </span>
                  )}
                </a>
              ) : (
                <a href="/login">
                  <span className="cart-sec">
                    <img src={cart} />
                  </span>
                </a>
              )}
            </>

            <div>
              {isUser ? (
                <a className="btton-log" onClick={handleLogout}>
                  LOG OUT
                </a>
              ) : (
                <a className="btton-log" href="/login">
                  LOG IN
                </a>
              )}
            </div>

            <div>
              {!isUser && (
                <a className="btton-sign" href="/registration">
                  SIGN UP
                </a>
              )}
            </div>
          </div>
          <a href="/">
            <img src={rrclogo} />
          </a>
          <span style={{ fontSize: 30 }} onClick={() => openNav()}>
            &#9776;
          </span>
        </div>
      </div>
    </div>
  );
};
export default Header;
