import "./thankyou.css";
import thank from "../../../assets/images/thankyou.png";
import Header from "../RRCHeader";

const thankyou = () => {
    return (<div className="thank_main flt">
        <Header />
        <div className="thankyou_img flt">
            <div className="thank_main flt">
                <div className="thankimg flt">
                    <img src={thank} />
                </div>
                <div className="thankyou_text flt">
                    We hope you enjoy your purchase
                </div>
                {/* <div className="thankyou_subtext flt">
                    we hope you enjoy your purchase
                </div> */}
            </div>
        </div>

    </div>)
}
export default thankyou;