import { useEffect, useState } from "react";
import { alertActions } from "../../../actions/alert.actions";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { UserService } from "../../../services";

const useForgotPasswordHook = () => {
  let history = useNavigate();
  const [mobile, setMobile] = useState("");
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = useState(false);
  const [forgotPasswordData, setForgotPasswordData] = useState({});

  useEffect(() => {
    if (forgotPasswordData?.data?.status?.code === 0) {
      setMobile("");
      setSubmitted(false);
      setTimeout(() => {
        dispatch(
          alertActions.success(
            "Password reset link has been sent to your registered email"
          )
        );
      }, 50);
    } else {
      dispatch(alertActions.error(forgotPasswordData?.data?.status?.message));
    }
  }, [forgotPasswordData]);

  function handleChange(e) {
    setMobile(e.target.value);
  }
  //API CALL
  function handleSubmit(e) {
    e.preventDefault();
    setSubmitted(true);
    var filter = /^[0-9]{10}$/;
    // 
    if (mobile) {
      if (!filter.test(mobile)) {
        dispatch(alertActions.error("Enter Valid Mobile Number"));
      } else {
        UserService.forgotPassword(mobile)
          .then((response) => {
            setForgotPasswordData(response);
          })
          .catch((error) => {
            console.log("Errorreg", error);
          });
      }
    }
  }

  return {
    mobile,
    submitted,
    handleChange,
    handleSubmit,
  };
};
export default useForgotPasswordHook;
