import "./Event.css";
import eventimg from "../../../assets/images/eventimg.png";
import Header from "../RRCHeader";

const Events = () => {
  return (
    <div className='main-page flt'>
        <Header />
      <div className='blog-main flt'>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-12'>
              <div className='event-text flt'>Events</div>
            </div>
            <div className='blog-divdr flt '>
              <hr />
            </div>
          </div>
        </div>
      </div>

      <div className='event-main flt'>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-3'>
              <div className='event-maindiv flt'>
                <div className='event-img flt'>
                  <img src={eventimg} />
                </div>
                <div className='event-date flt'>March 3, 2022</div>
                <div className='event-headtxt flt'>Lorem ipsum</div>
                <div className='event-contnttxt flt'>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non,
                  proin et vel penatibus.
                </div>
              </div>
            </div>
            <div className='col-sm-3'>
              <div className='event-maindiv flt'>
                <div className='event-img flt'>
                  <img src={eventimg} />
                </div>
                <div className='event-date flt'>March 3, 2022</div>
                <div className='event-headtxt flt'>Lorem ipsum</div>
                <div className='event-contnttxt flt'>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non,
                  proin et vel penatibus.
                </div>
              </div>
            </div>
            <div className='col-sm-3'>
              <div className='event-maindiv flt'>
                <div className='event-img flt'>
                  <img src={eventimg} />
                </div>
                <div className='event-date flt'>March 3, 2022</div>
                <div className='event-headtxt flt'>Lorem ipsum</div>
                <div className='event-contnttxt flt'>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Non,
                  proin et vel penatibus.
                </div>
              </div>
            </div>
            <div className='col-sm-3'></div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Events;
