import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { alertActions } from "../../../actions";
import { UserService } from "../../../services";

const useGetCartHook = () => {
  // const user_id = useSelector((state) => state?.userData?.user?.roleId);
  const [cartData, setCartData] = useState([]);
  const arr = [];
  const [cartRes, setCartRes] = useState({});
  const [cart, setCart] = useState();
  let history = useNavigate();
  const dispatch = useDispatch();
  const [arrData, srtArrData] = useState([]);
  const [amount, setAmount] = useState(0);
  let dataSum = 0;
  const [loading, setLoading] = useState("loading");

  useEffect(() => {
    // handleGetCart();
    // cartData.map((res) =>
    //   arr.push(res)
    // )
    UserService.getCart()
      .then((response) => {

        setCartData(response?.data?.data);
        response.data.data.result.map((res) => {
          arr.push(res._id)
          if (res.category === "image") {
            dataSum = parseFloat(dataSum) + parseFloat(res?.image?.[0]?.price);
          }
          else if (res.category === "audio") {
            dataSum = parseFloat(dataSum) + parseFloat(res?.audio?.[0]?.price);
          } else {
            dataSum = parseFloat(dataSum) + parseFloat(res?.video?.[0]?.price);
          }
        }

        )
        setLoading("notempty");
        setAmount(dataSum);
        srtArrData(arr);
        setCartRes(response);
        setCart(response?.data?.data?.count);
      })
      .catch((error) => {
        setLoading("empty");
        console.log("Errorreg", error);
      });
  }, []);

  //redirect checkout
  // function handleCheckout(

  // ) {
  //   history("/checkout");
  // }
  const handleCheckout = () => {
    // UserService.checkoutCartItem(arrData, amount);
    history("/checkout");
  }
  //remove Cart
  function handleRemoveCart(id) {
    UserService.removeCart(id)
      .then((response) => {
        if (response?.data?.status?.code === 0) {
          handleGetCart();
          dispatch(alertActions.success(response?.data?.status?.message));
        } else {
          dispatch(alertActions.error(response?.data?.status?.message));
        }
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  }

  //get cart
  function handleGetCart() {
    UserService.getCart()
      .then((response) => {

        setCartData(response?.data?.data);
        response.data.data.result.map((res) =>
          arr.push(res._id)


        )
        setCartRes(response);
        setCart(response?.data?.data?.count);
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  }
  return { cartRes, handleCheckout, handleRemoveCart, cart, loading };
};
export default useGetCartHook;
