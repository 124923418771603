import { Link } from "react-router-dom";
import rrclogo from "../../../assets/images/rrclogo.png";
import Vector from "../../../assets/images/Vector.png";
import "../../pages/RrcHomeScreen/home.css";

const Footer = () => {
  return (
    <div className="footer_container flt">
      <div className="footer-main flt" id="footer-sec">
        <div className="container">
          <div className="row">
            <div className="col-sm-2"></div>
            <div className="col-sm-8 footer-content">
              <ul className="footer-menu flt">
                <li className="active">
                  <Link to="/#" className="footer-menu">
                    {" "}
                    HOME{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/gallery"> GALLERY </Link>
                </li>
                <li>
                  <Link to="/list"> DETAILS LIST </Link>
                </li>
                <li>
                  <Link to="/blogs"> BLOG </Link>
                </li>
                <li>
                  <Link to="/contact"> CONTACT </Link>
                </li>
              </ul>
            </div>
            <div className="col-sm-2"></div>
          </div>
        </div>
      </div>
      <div className="copyright flt">
        <div className="container">
          <div className="row footer-sec-last">
            <div className="col-sm-4">
              <div className="bottm-footer flt">
                {" "}
                <a href="https://www.chipsyservices.com/" target="_blank">
                  Designed by Chipsy Information Technology
                </a>{" "}
              </div>
            </div>
            <div className="col-sm-4">
              <div className="footer-logo flt">
                <a href="/">
                  <img src={rrclogo} />
                </a>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="footer-txtbtn flt">
                <div className="footer-back flt">
                  <button
                    className="back-to-top-btn"
                    onClick={() => {
                      window.scrollTo(500, 0);
                    }}
                  >
                    <span className="back-to-top">Back to Top</span>
                    <img src={Vector} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
