import { SAVE_USER_DATA, USER_LOGOUT } from "./types";
import { UserService } from "../services/UserService";

//user login action
export const userLogin = (username, password) => (dispatch) => {
  return UserService.login(username, password).then((data) => {
    if (data?.data?.status?.code === 0) {
      dispatch({
        type: SAVE_USER_DATA,
        payload: {
          token: data?.data?.data?.access_token,
          user: data?.data?.data?.user,
          type: data?.data?.data?.token_type,
        },
      });
    }
    return Promise.resolve(data);
  });
};
export const registration = (name, email, password, mobile) => (dispatch) => {
  return UserService.registration(name, email, password, mobile).then(
    (data) => {
      if (data.data.status.code === 0) {
        dispatch({
          type: SAVE_USER_DATA,
          payload: {
            token: data?.data?.data?.access_token,
            user: data?.data?.data?.user,
            type: data?.data?.data?.token_type,
          },
        });
      }
      return Promise.resolve(data);
    }
  );
};
export function userLogout() {
  return function (dispatch) {
    const action = {
      type: USER_LOGOUT,
    };
    localStorage.clear();
    dispatch(action);
  };
}
