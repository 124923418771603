import * as axios from "../network/Network";

const config = require("../network/api.json");

//user login service
class UserService {
  static login(username, password) {
    return axios.appRequest({
      method: axios.POST,
      url: config.user.login,
      data: { username, password },
    });
  }

  //user registration service
  static registration(name, email, mobile, password) {
    return axios.appRequest({
      method: axios.POST,
      url: config.user.register,
      data: { name, email, mobile, password },
    });
  }

  //user list service
  static getDocs(page, category, search, sortBy, sortKey) {
    const result = axios.appRequest({
      method: axios.GET,
      url: `${config.user.userDocs}?page=${page}&mediatype=${category}&search=${search}&sortBy=${sortBy}&sortKey=${sortKey}`,
      data: { page, category, search, sortBy, sortKey },
    });
    return Promise.resolve(result);
  }
  //image List Dispaly
  static imageDocs(page, category, search, type) {
    const result = axios.appRequest({
      method: axios.GET,
      url: `${config.user.imageList}?page=${page}&mediatype=${category}&search=${search}&type=${type}`,
      data: { page, category, search, type },
    });
    return Promise.resolve(result);
  }

  //user add cart
  static addCart(product_id, category, quantity, total_amount) {
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.addCart,
      data: { product_id, category, quantity, total_amount },
    });
    return Promise.resolve(result);
  }
  //user get cart datial
  static cartDetail(id, category) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.cartDetail + `/${id}?category=${category}`,
      data: { category },
    });
    return Promise.resolve(result);
  }
  //forgot password
  static forgotPassword(username) {
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.forgotPassword,
      data: { username },
    });
    return Promise.resolve(result);
  }
  //confirm password
  static confirmPassword(password, random) {
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.confirmPassword,
      data: { password, random },
    });
    return Promise.resolve(result);
  }
  //get cart
  static getCart() {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.getCart,
    });
    return Promise.resolve(result);
  }
  //remove cart
  static removeCart(_id) {
    const result = axios.appRequest({
      method: axios.DELETE,
      url: config.user.removeCart,
      data: { _id },
    });
    return Promise.resolve(result);
  }
  //checkout cart
  static checkoutCart(arr) {

    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.checkout,
      data: { arr },
    });
    return Promise.resolve(result);
  }

  //user checkout
  static checkoutCartItem(cartdetails, amount, proper_status, singleamount) {
    const result = axios.appRequest({
      method: axios.POST,
      url: config.user.checkout,
      data: { cartdetails, amount, proper_status, singleamount },
    });
    return Promise.resolve(result);
  }

  //get orderhistory
  //get cart
  static getOrderHistory(page) {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.myAccount + `?page=${page}`,
      data: { page },
    });
    return Promise.resolve(result);
  }
  //get user profile
  static userprofile() {
    const result = axios.appRequest({
      method: axios.GET,
      url: config.user.userprofile,
    });
    return Promise.resolve(result);
  }
}
export { UserService };
