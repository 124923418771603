import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { alertActions, userLogout } from "../../../actions";
import Header from "../RRCHeader";
import useGetCartHook from "./useGetCartHook";
import "./GetCart.css";
import noImage from "../../../assets/images/defaultimg.png";
import noVideo from "../../../assets/images/defaultvideo.png";
import noAudio from "../../../assets/images/audio-default.png";
import { commaValidation } from "../../../utility";
import audioImgPopUp from "../../../assets/images/aud.png";
import emptycart from "../../../assets/images/emptycart.png";
import loader from "../../../assets/images/logo.png";

const GetCart = (tokenExpired) => {
  const { cartRes, handleCheckout, handleRemoveCart, cart, loading } =
    useGetCartHook();
  const dispatch = useDispatch();

  let history = useNavigate();
  const buttonsubmit = () => {
    history("/gallery");
  };
  useEffect(() => {
    if (cartRes?.data?.data?.result) console.log("here");
  }, [cartRes?.data?.data?.result]);

  useEffect(() => {
    if (tokenExpired.tokenExpired) {
      setTimeout(() => {
        dispatch(alertActions.error("Authentication error..Please login"));
      }, 50);

      dispatch(userLogout());
      history("/login");
    }
  }, [tokenExpired.tokenExpired]);

  const getCartLIst = (data) => {
    return data.map((e, index) => {
      var type;
      if (e?.image[0]) {
        if (e.quantity == "1") {
          type = `${e.image[0].mediatype}`;
        } else {
          type = `${e.image[0].mediatype}s`;
        }
      } else if (e?.audio[0]) {
        if (e.quantity == "1") {
          type = `${e.audio[0].mediatype}`;
        } else {
          type = `${e.audio[0].mediatype}s`;
        }
      } else if (e?.video[0]) {
        if (e.quantity == "1") {
          type = `${e.video[0].mediatype}`;
        } else {
          type = `${e.video[0].mediatype}s`;
        }
      }
      console.log("asdasd", type);
      return (
        <>
          {e?.image[0]?.mediatype === "image" && (
            <div className="main-div-cart">
              {e?.image[0]?.url ? (
                <div className="image-div-cart flt">
                  <img
                    className="image-sec-list"
                    src={`${process.env.REACT_APP_MEDIA_DOMAIN}${e?.image[0]?.url}`}
                  />
                </div>
              ) : (
                <div className="image-div-cart">
                  <img className="image-sec-list" src={`${noImage}`} />
                </div>
              )}
              <div className="content-div-cart">
                <div className="cart_first">
                  <p className="item-title">
                    {e?.image[0]?.itemName}{" "}
                    <span>
                      ({e?.image[0]?.numberOfDocs} {type})
                    </span>
                  </p>
                  <p className="item-date">{e?.image[0]?.date}</p>
                  <p className="item-date">{e?.image[0]?.category}</p>
                  <p className="item-price">
                    &#8377;{commaValidation(e?.image[0]?.price)}
                  </p>
                </div>
                <div className="button-sec">
                  {/* <button
                    onClick={() =>
                      handleCheckout(
                        e._id,
                        e?.image[0]?.price,
                        e.quantity,
                        e?.image[0]?.itemName,
                        e?.image[0]?.numberOfDocs,
                        type,
                        e?.image[0]?.url
                      )
                    }
                    className="checkout-button"
                  >
                    Proceed to checkout
                  </button> */}
                  <button
                    onClick={() => handleRemoveCart(e._id)}
                    className="rm-cart-buttons"
                  >
                    Remove From Cart
                  </button>
                </div>
              </div>
            </div>
          )}
          {e?.audio[0]?.mediatype === "audio" && (
            <div className="main-div-cart">
              {e?.audio[0]?.url || e?.audio[0]?.url != "" ? (
                <div className="image-div-cart">
                  <img className="table-image" src={audioImgPopUp} />
                  <audio controls className="audio">
                    <source
                      src={`${process.env.REACT_APP_MEDIA_DOMAIN}${e?.audio[0]?.url}`}
                      type="audio/mpeg"
                    />
                  </audio>
                </div>
              ) : (
                <div className="image-div-cart">
                  <img className="image-sec-list" src={`${noAudio}`} />
                </div>
              )}
              <div className="content-div-cart">
                <div className="cart_first">
                  <p className="item-title">
                    {e?.audio[0]?.itemName}{" "}
                    <span>
                      ({e?.audio[0]?.numberOfDocs} {type})
                    </span>
                  </p>
                  <p className="item-date">{e?.audio[0]?.recordingdate}</p>
                  <p className="item-date">{e?.audio[0]?.category}</p>
                  <p className="item-price">
                    &#8377;{commaValidation(e?.audio[0]?.price)}
                  </p>
                </div>
                <div className="button-sec">
                  {/* <button
                    onClick={() =>
                      handleCheckout(
                        e._id,
                        e?.audio[0]?.price,
                        e.quantity,
                        e?.audio[0]?.itemName,
                        e?.audio[0]?.numberOfDocs,
                        type,
                        e?.audio[0]?.url
                      )
                    }
                    className="checkout-button"
                  >
                    Proceed to checkout
                  </button> */}
                  <button
                    onClick={() => handleRemoveCart(e._id)}
                    className="rm-cart-buttons"
                  >
                    Remove From Cart
                  </button>
                </div>
              </div>
            </div>
          )}
          {e?.video[0]?.mediatype === "video" && (
            <div className="main-div-cart">
              {e?.video[0]?.url || e?.video[0]?.url != "" ? (
                <div className="image-div-cart">
                  <video
                    className="video_main1"
                    width="280"
                    height="180"
                    controls
                  >
                    <source
                      src={`${process.env.REACT_APP_MEDIA_DOMAIN}${e?.video[0]?.url}`}
                      type="video/mp4"
                    />
                  </video>
                </div>
              ) : (
                <div className="image-div-cart">
                  <img className="image-sec-list" src={`${noVideo}`} />
                </div>
              )}
              <div className="content-div-cart">
                <div className="cart_first">
                  <p className="item-title">
                    {e?.video[0]?.itemName}{" "}
                    <span>
                      ({e?.video[0]?.numberOfDocs} {type})
                    </span>
                  </p>
                  <p className="item-date">{e?.video[0]?.recordingdate}</p>
                  <p className="item-date">{e?.video[0]?.category}</p>
                  <p className="item-price">
                    &#8377;{commaValidation(e?.video[0]?.price)}
                  </p>
                </div>
                <div className="button-sec">
                  {/* <button
                    onClick={() =>
                      handleCheckout(
                        e._id,
                        e?.video[0]?.price,
                        e.quantity,
                        e?.video[0]?.itemName,
                        e?.video[0]?.numberOfDocs,
                        type,
                        e?.video[0]?.url
                      )
                    }
                    className="checkout-button"
                  >
                    Proceed to checkout
                  </button> */}
                  <button
                    onClick={() => handleRemoveCart(e._id)}
                    className="rm-cart-buttons"
                  >
                    Remove From Cart
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      );
    });
  };
  const pro_checkout = () => {
    return (
      <div className="proceed_checkout flt">
        <button
          onClick={() => handleCheckout()}
          className="checkout-button space_around"
        >
          Proceed to Checkout
        </button>
      </div>
    );
  };

  return (
    <div>
      <Header cart={cart} />
      {loading === "notempty" ? (
        <div className="get-cart-div flt">
          <div className="cart-sectn">
            <div className="chckot flt">Cart</div>
            {cartRes?.data?.data?.result &&
            cartRes?.data?.data?.result.length !== 0 ? (
              getCartLIst(cartRes?.data?.data?.result)
            ) : (
              <div className="empty_cart flt">
                <div className="empty_main flt">
                  <img src={emptycart} />
                </div>
                <div className="empty_content flt">
                  <div className="empty_section flt">Your cart is empty</div>
                  <div className="empty_sec flt">
                    Looks like you have not added any media files to your cart.
                  </div>
                  <div className="empty_button flt">
                    <button onClick={() => buttonsubmit()}>Add Files</button>
                  </div>
                </div>
              </div>
            )}
            {cartRes?.data?.data?.result &&
            cartRes?.data?.data?.result.length !== 0
              ? pro_checkout()
              : ""}
          </div>
        </div>
      ) : (
        <div className="cartpg flt">
          <img src={loader} alt="rrc" />
        </div>
      )}
    </div>
  );
};
export default GetCart;
