import useRegistrationHooks from "./useRegistrationHooks";
import Logo from "../../../assets/images/logo.png";
import "./Registration.css";
import "../RRCLoginScreen/Login.css";
import Header from "../RRCHeader";
const Registration = () => {
  const {
    name,
    email,
    mobile_no,
    password,
    re_password,
    changeNameHandler,
    changeEmailHandler,
    changeMobileNoHandler,
    changePasswordHandler,
    changeRePasswordHandler,
    submitted,
    handleSubmit,
  } = useRegistrationHooks();

  const RegistrationMain = () => {
    return (
      <>
        <Header />
        <div className="login_container flt">
          <div className="login_box">
            <div className="login_cont flt">
              <div style={{ padding: "10%" }}>
                <img src={Logo} style={{ width: "100%" }} />
              </div>
              <div>
                <p className="sec-header">Register Your Account</p>
              </div>

              <div className="form-group flt">
                <input
                  type="text"
                  placeholder="Enter Full Name"
                  name="name"
                  value={name}
                  autoComplete="off"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit(e);
                    }
                  }}
                  onChange={changeNameHandler}
                  className={
                    submitted && !name ? "input-sec is-invalid" : "input-sec"
                  }
                />
                {submitted && !name && (
                  <div className="invalid-feedback">Name is required</div>
                )}
              </div>
              <div className="form-group flt">
                <input
                  type="text"
                  placeholder="Enter Email ID"
                  name="email"
                  value={email.trim()}
                  onChange={changeEmailHandler}
                  autoComplete="off"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit(e);
                    }
                  }}
                  className={
                    submitted && !email ? "input-sec is-invalid" : "input-sec"
                  }
                />
                {submitted && !email && (
                  <div className="invalid-feedback">Email is required</div>
                )}
              </div>
              <div className="form-group flt">
                <div className="login_ip flt">
                  <input
                    type="number"
                    placeholder="Enter Mobile Number"
                    name="mobile_no"
                    value={mobile_no}
                    autoComplete="off"
                    onChange={changeMobileNoHandler}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSubmit(e);
                      }
                    }}
                    className={
                      submitted && !mobile_no
                        ? "input-sec is-invalid"
                        : "input-sec"
                    }
                  />
                </div>
                {submitted && !mobile_no && (
                  <div className="invalid-feedback invalid-mob">
                    Mobile number is required
                  </div>
                )}
              </div>
              <div className="form-group flt">
                <input
                  type="password"
                  placeholder="Enter Password"
                  name="password"
                  value={password}
                  autoComplete="off"
                  onChange={changePasswordHandler}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit(e);
                    }
                  }}
                  className={
                    submitted && !password
                      ? "input-sec is-invalid"
                      : "input-sec"
                  }
                />

                {submitted && !password && (
                  <div className="invalid-feedback">Password is required</div>
                )}
              </div>
              <div className="form-group flt">
                <input
                  type="password"
                  placeholder="Confirm Password"
                  name="re_password"
                  value={re_password}
                  autoComplete="off"
                  onChange={changeRePasswordHandler}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit(e);
                    }
                  }}
                  className={
                    submitted && !re_password
                      ? "input-sec is-invalid"
                      : "input-sec"
                  }
                />
                {!re_password && (
                  <div className="invalid-feedback">
                    Confirm password is required
                  </div>
                )}
              </div>

              <button className="login_btn flt" onClick={handleSubmit}>
                Register
              </button>

              <div className="reg-sec">
                <p className="reg-sec-content">
                  Already a member ? <a href="/login">login here</a>
                </p>
              </div>
            </div>
            <div className="image-sec-right">
              <div className="right-sec">
                <p className="right-sec-header">Welcome to RRC</p>
                <p className="right-sec-desc">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Mattis molestie amet blandit nisi. Morbi faucibus ut id tortor
                  semper eLorem ipsum dolor sit amet, consectetur adipiscing
                  elit. Mattis molestie amet blandit nisi. Morbi faucibus ut id
                  tortor semper e
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return <div>{RegistrationMain()}</div>;
};
export default Registration;
