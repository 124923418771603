import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { UserService } from "../../../services";
const useMyAccount = () => {
    const user = JSON.parse(localStorage.getItem("state"));
    const isUser = user ? Object.keys(user.userData).length : 0;
    // alert(JSON.stringify(user.userData.user._id))
    const usrid = user.userData.user._id;
    const [show, setShow] = useState(1);
    const [userprof, setUserProf] = useState([]);
    const [pageCount, setPageCount] = useState();
    const [current, setCurrent] = useState(1);
    const stateChange = () => {

        setShow(0)
    }
    const [userData, setUserData] = useState([]);
    const stateChange1 = () => {

        setShow(1)
    }
    const changeCurrentPage = (page) => {

        setCurrent(page.selected + 1);
        localStorage.setItem("pagination", page.selected + 1);
    };
    useEffect(() => {
        //userprofile
        UserService.userprofile().then((res) => {
            setUserProf(res?.data?.data?.user)
        })
    }, [])
    useEffect(() => {
        UserService.getOrderHistory(current).then((res) => {
            setPageCount(res?.data?.data?.totalPages);
            setUserData(res?.data?.data?.docs)
        })

    }, [current])
    return {
        stateChange, stateChange1, show, userData, userprof, pageCount, current, changeCurrentPage
    }
}
export default useMyAccount;