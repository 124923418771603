import useListHook from "./useListHook";
import DataTable from "react-data-table-component";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import noImage from "../../../assets/images/defaultimg.png";
import "./List.css";
import loader from "../../../assets/images/logo.png";
import Header from "../RRCHeader";
import CustomDropDown from "../RRCCustomDropDown";
import image from "../../../assets/images/image.png";
import audioimg from "../../../assets/images/audioimg.png";
import video from "../../../assets/images/video.png";
import { alertActions, userLogout } from "../../../actions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { UserService } from "../../../services";
import { commaValidation } from "../../../utility";
import noAudio from "../../../assets/images/audio-default.png";
import noVideo from "../../../assets/images/defaultvideo.png";
import audio from "../../../assets/images/audio.png";
import audioImgPopUp from "../../../assets/images/aud.png";
import nodata from "../../../assets/images/nodata.png";

const List = (tokenExpired) => {
  const {
    listRes,
    changeMediaHandler,
    media,
    changeSearchHandler,
    resetFilter,
    search,
    changeCurrentPage,
    page,
    handleAddCart,
    loading,
    cart,
    category,
    sortData,
    showModal,
    closeModal,
    handleAudioModal,
    showUrl,
    handleRemoveCart,
    refresh,
  } = useListHook();
  const [listTable, setListTable] = useState([]);

  const dispatch = useDispatch();
  let history = useNavigate();
  useEffect(() => {
    if (tokenExpired.tokenExpired) {
      setTimeout(() => {
        dispatch(alertActions.error("Authentication error..Please login"));
      }, 50);

      dispatch(userLogout());
      history("/login");
    }
  }, [tokenExpired.tokenExpired]);

  // useEffect(() => {

  // }, [refresh])

  const emptypg = () => {
    return (
      <div className='cartpg flt'>
        <img src={loader} alt='rrc' />
      </div>
    );
  };
  const options = [
    {
      value: "Image",
      label: (
        <span className='option-sec'>
          <img src={image}></img>Image
        </span>
      ),
    },
    {
      value: "Audio",
      label: (
        <span className='option-sec'>
          <img src={audioimg}></img>Audio
        </span>
      ),
    },
    {
      value: "Video",
      label: (
        <span className='option-sec'>
          <img src={video}></img>Video
        </span>
      ),
    },
  ];
  var columns = [];
  if (listRes?.data?.mediatype === "image") {
    columns = [
      {
        name: "Image",
        selector: (row) => row.source,
      },
      {
        name: "Date",
        selector: "date",
      },
      {
        name: "Wallet Number",
        selector: (row) => row.walletNumber,
        sortable: true,
        sortField: "walletNumber",
      },
      {
        name: "Category",
        selector: (row) => row.category,
        sortable: true,
        sortField: "category",
      },
      {
        name: "Item Name",
        selector: (row) => row.itemName,
        sortable: true,
        sortField: "itemName",
      },
      {
        name: "Place",
        selector: (row) => row.place,
        sortable: true,
        sortField: "place",
      },
      {
        name: "Photos",
        selector: (row) => row.photos,
        sortable: true,
        sortField: "numberOfDocs",
      },
      {
        name: "Type",
        selector: (row) => row.type,
        sortable: true,
        sortField: "type",
      },
      {
        name: "Price",
        selector: (row) => row.price,
        sortable: true,
        sortField: "price",
      },
      {
        name: "",
        selector: (row) => row.cart,
      },
    ];
  } else if (listRes?.data?.mediatype === "audio") {
    columns = [
      {
        name: "Audio",
        selector: (row) => row.audio,
      },
      {
        name: "Title",
        selector: (row) => row.title,
        sortable: true,
        sortField: "itemName",
      },
      {
        name: "Author",
        selector: (row) => row.author,
        sortable: true,
        sortField: "performer_informer_collection",
      },
      {
        name: "Audio No",
        selector: (row) => row.audioNO,
        sortable: true,
        sortField: "audio_no",
      },
      {
        name: "Recording Date ",
        selector: (row) => row.recordingdate,
        // sortable: true,
      },
      {
        name: "Recording Place",
        selector: (row) => row.recordingplace,
        sortable: true,
        sortField: "recordingplace",
      },
      {
        name: "Price",
        selector: (row) => row.price,
        sortable: true,
        sortField: "price",
      },
      {
        name: "",
        selector: (row) => row.cart,
      },
    ];
  } else if (listRes?.data?.mediatype === "video") {
    columns = [
      {
        name: "Video",
        selector: (row) => row.video,
      },
      {
        name: "Date",
        selector: "date",
      },
      {
        name: "Video No",
        selector: (row) => row.walletNumber,
        sortable: true,
        sortField: "walletNumber",
      },
      {
        name: "Category",
        selector: (row) => row.category,
        sortable: true,
        sortField: "category",
      },
      {
        name: "Item Name",
        selector: (row) => row.itemName,
        sortable: true,
        sortField: "itemName",
      },
      {
        name: "Place",
        selector: (row) => row.place,
        sortable: true,
        sortField: "place",
      },
      // {
      //   name: "Videos",
      //   selector: (row) => row.photos,
      //   sortable: true,
      //   sortField: "photos",
      // },

      {
        name: "Price",
        selector: (row) => row.price,
        sortable: true,
        sortField: "price",
      },
      {
        name: "",
        selector: (row) => row.cart,
      },
    ];
  }

  const handleSort = async (column, sortDirection) => {

    sortData(column.sortField, sortDirection);
  };

  // {
  //   data && data.data && data.data.docs !== null ? data.data.docs.map((list, index) => (
  //     <tr key={list._id}>
  //       <td>{20 * (data.data.page - 1) + index + 1}.</td>
  //       }
  let list = [];
  var obj = {};
  const listData = () => {
    listRes?.data?.docs.map((e, index) => {
      if (listRes?.data?.mediatype === "image") {
        obj = { 
          id: e._id,
          source:
            e.url || e.url != "" ? (
              <div className='tbl-cell-data img' onClick={() => handleAudioModal(e.url)}>
                <img
                  className='image-sec-list'
                  src={`${process.env.REACT_APP_MEDIA_DOMAIN}${e.url}`}
                />
              </div>
            ) : (
              <div className="displayopacity">
                <img className={`image-sec image`} src={`${noImage}`} />
              </div>
            ),
          // date: e.date ? <div className='tbl-cell-data' title={e.date}>{e.date}<div className="tbl_data">{e.date}</div></div> : "",
          // walletNumber: e.walletNumber ? <div>{e.walletNumber}<div className="datevisible">{e.walletNumber}</div></div> : "",
          date: e.date ? (
            <div className='tbl-cell-data' title={e.date}>
              {e.date}
              <div className="tbl_data">{e.date}</div>
            </div>
          ) : (
            "-"
          ),


          walletNumber: e.walletNumber ? (
            <div className='tbl-cell-data' title={e.walletNumber}>
              {e.walletNumber}
              <div className="tbl_data">{e.walletNumber}</div>
            </div>
          ) : (
            "-"
          ),

          category: e.category ? (
            <div className='tbl-cell-data' title={e.category}>
              {e.category}
              <div className="tbl_data">{e.category}</div>
            </div>
          ) : (
            "-"
          ),
          itemName: e.itemName ? (
            <div className='tbl-cell-data' title={e.itemName}>
              {e.itemName}
              <div className="tbl_data">{e.itemName}</div>
            </div>

          ) : (
            "-"
          ),
          place: e.place ?
            <div className='tbl-cell-data' title={e.place}>{e.place}
              <div className="tbl_data">{e.place}</div>
            </div> : "-",
          photos: e.numberOfDocs ? (
            <div className='tbl-cell-data' title={e.numberOfDocs}>
              {e.numberOfDocs}
              <div className="tbl_data">{e.numberOfDocs}</div>
            </div>
          ) : (
            "-"
          ),
          type: e.type ? (
            <div className='tbl-cell-data' title={e.type}>
              {e.type}
              <div className="tbl_data">{e.type}</div>
            </div>
          ) : (
            "-"
          ),
          price: e.price ? <div>{`₹${commaValidation(e.price)}`}<div className="datevisible">{`₹${commaValidation(e.price)}`}</div></div> : "-",

          cart: (
            <div className='removecart'>
              {e?.carts?.length === 0 ? (
                <button
                  className={`add-to-cart image`}
                  onClick={() =>
                    handleAddCart(e._id, e.mediatype, e.numberOfDocs, (e.price + (e.price * listRes?.data?.tax) / 100))
                  }
                >
                  Add
                </button>

              ) : (
                <div className='removecart'>
                  <button
                    onClick={() => handleRemoveCart(e?.carts[0]?._id)}
                    className='add-to-cart image'
                  >
                    Remove
                  </button>
                </div>
              )}
              <div className="datevisible">{`₹${commaValidation(e.price)}`}</div>
            </div>
          ),
        };
      } else if (listRes?.data?.mediatype === "audio") {
        obj = {
          id: e._id,

          audio:
            e.url || e.url != "" ? (
              <div onClick={() => handleAudioModal(e.url)}>
                <img className={`image-sec audio`} src={`${noAudio}`} />
              </div>
            ) : (
              <div className="displayopacity">
                <img className={`image-sec audio`} src={`${noAudio}`} />
              </div>
            ),
          title: e.itemName ? (
            <>
              {/* <img className="image-sec-list" src={`${audio}`} /> */}

              <span className='tbl-cell-data' title={e.itemName}>
                {e.itemName}
                <div className="tbl_data">{e.itemName}</div>
              </span>
            </>
          ) : (
            "-"
          ),
          author: e.performer_informer_collection ? (
            // <div
            //   className='tbl-cell-data'
            //   title={e.performer_informer_collection}
            // >
            //   {e.performer_informer_collection}
            //   <div className="tbl_data">{e.collection}</div>
            //   <div className="datevisible">{e.audio_no}</div>
            // </div>
            <>
              {/* <img className="image-sec-list" src={`${audio}`} /> */}

              <span className='tbl-cell-data' title={e.performer_informer_collection}>
                {e.performer_informer_collection}
                <div className="tbl_data">{e.performer_informer_collection}</div>
              </span>
            </>
          ) : (
            "-"
          ),

          audioNO: e.audio_no ? (
            <div className='tbl-cell-data' title={e.audio_no}>
              {e.audio_no}
              <div className="tbl_data">{e.audio_no}</div>
            </div>
          ) : (
            "-"
          ),

          recordingdate: e.recordingdate ? (
            <div className='tbl-cell-data' title={e.recordingdate}>
              {e.recordingdate}
              <div className="tbl_data">{e.recordingdate}</div>
            </div>
          ) : (
            "-"
          ),

          recordingplace: e.recordingplace ? (
            <div className='tbl-cell-data' title={e.recordingplace}>
              {e.recordingplace}
              <div className="tbl_data">{e.recordingplace}</div>
            </div>
          ) : (
            "-"
          ),

          // audioNO: e.audio_no ? <div>{e.audio_no}<div className="datevisible">{e.audio_no}</div></div> : "",
          // recordingdate: e.recordingdate ? <div>{e.recordingdate}<div className="datevisible">{e.recordingdate}</div></div> : "",
          // recordingplace: e.recordingplace ? <div>{e.recordingplace}<div className="datevisible">{e.recordingplace}</div></div> : "",
          price: e.price ? <div>{`₹${commaValidation(e.price)}`}<div className="datevisible">{`₹${commaValidation(e.price)}`}</div></div> : "NA",

          cart: (
            <div className='removecart'>
              {e?.carts?.length === 0 ? (
                <button
                  className={`add-to-cart audio`}
                  onClick={() =>
                    handleAddCart(e._id, e.mediatype, e.numberOfDocs, (e.price + (e.price * listRes?.data?.tax) / 100))
                  }
                >
                  Add
                </button>
              ) : (
                <div className='removecart'>
                  <button
                    onClick={() => handleRemoveCart(e.carts[0]._id)}
                    className='add-to-cart image'
                  >
                    Remove
                  </button>
                </div>
              )}
              <div className="datevisible">{`₹${commaValidation(e.price)}`}</div>
            </div>
          ),
        };
      } else if (listRes?.data?.mediatype === "video") {
        obj = {
          id: e._id,
          video:
            e.url || e.url != "" ? (
              <div onClick={() => handleAudioModal(e.url)}>
                <img className={`image-sec video`} src={`${noVideo}`} />
              </div>
            ) : (
              <div className="displayopacity">
                <img className={`image-sec video`} src={`${noVideo}`} />
              </div>
            ),
          date: e?.recordingdate ? <div className='tbl-cell-data' title={e?.recordingdate}>{e?.recordingdate}<div className="tbl_data">{e?.recordingdate}</div></div> : "",
          // walletNumber: e.walletNumber ? <div>{e.walletNumber}<div className="datevisible">{e.walletNumber}</div></div> : "",

          walletNumber: e.video_no ? (
            <div className='tbl-cell-data' title={e.video_no}>
              {e.video_no}
              <div className="tbl_data">{e.video_no}</div>
            </div>
          ) : (
            "-"
          ),

          category: e.category ? (
            <div className='tbl-cell-data' title={e.category}>
              {e.category}
              <div className="tbl_data">{e.category}</div>
            </div>
          ) : (
            "-"
          ),
          itemName: e.itemName ? (
            <div className='tbl-cell-data' title={e.itemName}>
              {e.itemName}
              <div className="tbl_data">{e.itemName}</div>
            </div>
          ) : (
            "-"
          ),
          place: e?.recordingplace ? (
            <div className='tbl-cell-data' title={e?.recordingplace}>
              {e.recordingplace}
              <div className="tbl_data">{e?.recordingplace}</div>
            </div>
          ) : (
            "-"
          ),
          // place: e.place ? <div title={e.place}>{e.place}<div className="datevisible">{e.place}</div></div> : "",
          photos: e.numberOfDocs ? <div>{e.numberOfDocs}<div className="datevisible">{e.numberOfDocs}</div></div> : "",
          price: e.price ? <div>{`₹${commaValidation(e.price)}`}<div className="datevisible">{`₹${commaValidation(e.price)}`}</div></div> : "",

          cart: (
            <div className='removecart'>
              {e?.carts?.length === 0 ? (
                <button
                  className={`add-to-cart video`}
                  onClick={() =>
                    handleAddCart(e._id, e.mediatype, e.numberOfDocs, (e.price + (e.price * listRes?.data?.tax) / 100))
                  }
                >
                  Add
                </button>
              ) : (
                <div className='removecart'>
                  <button
                    onClick={() => handleRemoveCart(e.carts[0]._id)}
                    className='add-to-cart image'
                  >
                    Remove
                  </button>
                </div>
              )}
              <div className="datevisible">{`₹${commaValidation(e.price)}`}</div>
            </div>
          ),
        };
      }

      list.push(obj);
    });

    if (list.length) setListTable(list);
  };
  useEffect(() => {
    if (listRes?.data?.docs) listData();
  }, [listRes?.data?.docs, refresh]);
  //modal
  const detailModal = () => {
    // var footrClass = document.getElementById("footer-sec");
    // footrClass.className += " hide";
    return (
      <div class='modalPop' id='enquiryModal'>
        <div class='modal-dialog'>
          <div class='modal-content'>
            <div class='modal-body'>
              <div className='close-modal-sec-table'>
                <button
                  type='button'
                  className='close pop-up-close-table'
                  onClick={closeModal}
                >
                  <span aria-hidden='true'>&times;</span>
                </button>
              </div>
              {category === "image" && (
                <div className='table-pop-up-img'>
                  <img
                    className='table-image-cart'
                    src={`${process.env.REACT_APP_MEDIA_DOMAIN}${showUrl}`}
                  />
                </div>
              )}
              {category === "audio" && (
                <div className='table-pop-up-aud'>
                  <img className='able-image-cart' src={audioImgPopUp} />
                  <audio controls>
                    <source
                      src={`${process.env.REACT_APP_MEDIA_DOMAIN}${showUrl}`}
                      type='audio/mpeg'
                    />
                  </audio>
                </div>
              )}
              {category === "video" && (
                <div className='table-pop-up-aud table-pop-up-aud1'>
                  <video width='100%' controls>
                    <source
                      src={`${process.env.REACT_APP_MEDIA_DOMAIN}${showUrl}`}
                      type='video/mp4'
                    />
                  </video>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>
      <Header cart={cart} />
      {showModal && detailModal()}
      {loading === "notempty" ? (
        <>
          <div className='list-main-sec'>
            {/* <div className='list-filter-sec table-list'>
              <div className='custom-drop-down'>
                <CustomDropDown
                  defaultValue={options[0]}
                  value={media}
                  onChange={changeMediaHandler}
                  options={options}
                  classNamePrefix='select'
                />
              </div>

              <div className="list-filter-sec-div">
                <input
                  type='text'
                  placeholder='Search '
                  name='search'
                  value={search}
                  className='filter-search'
                  onChange={changeSearchHandler}
                />
                <button className='reset-btn-filter' onClick={() => resetFilter()}>Reset</button>
              </div>
            </div> */}

            <div className="search_main flt">
              <div className="row">
                <div className="col-sm-4">
                  <div className="search_left flt">
                    <button className={category === "image" ? "imagebutton" : ""} onClick={changeMediaHandler} name="image" value="image">
                      <img src={image} />
                      Image
                    </button>
                    <button className={category === "audio" ? "imagebutton" : ""} onClick={changeMediaHandler} name="audio" value="audio">
                      <img src={audioimg} />
                      Audio
                    </button>
                    <button className={category === "video" ? "imagebutton" : ""} onClick={changeMediaHandler} name="video" value="video">
                      <img src={video} />
                      Video
                    </button>
                  </div>
                </div>
                <div className="col-sm-8">
                  <div className="search_right flt">
                    <input
                      type='text'
                      placeholder='Search '
                      name='search'
                      value={search}
                      className='filter-search'
                      onChange={changeSearchHandler}
                    />
                    <div className="src_icon">
                      <i class="fas fa-search"></i>
                    </div>

                    {/* <button className='reset-btn-filter' onClick={() => resetFilter()}>Reset</button> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="data_display flt">
              {listRes?.data?.docs && listRes?.data?.docs.length !== 0 ? (
                <>
                  <DataTable
                    columns={columns}
                    data={listTable || []}
                    onSort={handleSort}
                    sortServer
                  // sortFunction={customSort}
                  />
                  <div className='paginationdiv flt'>
                    <ReactPaginate
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={listRes.data.totalPages}
                      currentPage={page}
                      showFirstLastPages={true}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={1}
                      forcePage={page - 1}
                      onPageChange={changeCurrentPage}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                </>
              ) : (
                <div className='no-data flt'>
                  <div className="nodata_img flt">
                    <img src={nodata} />
                  </div>
                  <div className="data_text flt">
                    No Data Found
                  </div>


                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        emptypg()
      )}
    </div>
  );
};

export default List;
