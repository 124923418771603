import "./MYAccount.css";
import Header from "../RRCHeader";
import useMyAccount from "./useMyAccount";
import myacc from "../../../assets/images/myacc.png";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { alertActions, userLogout } from "../../../actions";
import { useLocation, useNavigate } from "react-router";
import nodata from "../../../assets/images/nodata.png";
import ReactPaginate from "react-paginate";

const MyAccount = (tokenExpired) => {
  const {
    stateChange,
    stateChange1,
    show,
    userData,
    userprof,
    pageCount,
    current,
    changeCurrentPage,
  } = useMyAccount();
  const dispatch = useDispatch();
  let history = useNavigate();

  useEffect(() => {
    if (tokenExpired.tokenExpired) {
      setTimeout(() => {
        dispatch(alertActions.error("Authentication error..Please login"));
      }, 50);

      dispatch(userLogout());
      history("/login");
    }
  }, [tokenExpired.tokenExpired]);

  return (
    <>
      <Header />
      <div className="myaccount_main flt">
        <div className="row">
          <div className="col-sm-3">
            <ul className="accountList flt">
              <li onClick={stateChange1}>
                <a className={show === 1 ? "active" : ""}>MY ACCOUNT</a>
              </li>
              <li onClick={stateChange}>
                <a className={show === 0 ? "active" : ""}>MY ORDERS</a>
              </li>
            </ul>
          </div>

          <div className="col-sm-9">
            {show === 1 && (
              <div className="acc_holder flt">
                <div className="acc_detail flt">MY ACCOUNT</div>
                {/* {userData[0] && userData[0].user && userData[0].user && userData[0].user[0]} */}
                <div className="accsub_detail flt">
                  <div className="row">
                    <div className="col-sm-2">
                      <div className="myacc flt">
                        <img src={myacc} />
                      </div>
                    </div>
                    <div className="col-sm-10">
                      <ul className="subdetail flt">
                        <li className="subdtl">
                          <a>{userprof?.name}</a>
                        </li>
                        <li>
                          <a>Phone : {userprof?.mobile}</a>
                        </li>
                        <li>
                          <a>Email : {userprof?.email}</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {show === 0 && (
              <div className="main_table flt">
                <div className="my_order flt">MY ORDERS</div>
                <div className="table-responsive flt">
                  {userData && userData?.length >= 1 ? (
                    <table className="user_detail flt ">
                      <thead>
                        <tr>
                          <th>ORDER ID</th>
                          <th>CATEGORY</th>
                          <th>ITEM NAME</th>
                          <th>QUANTITY</th>
                          <th>PURCHASE DATE</th>
                          <th>WALLET/AUDIO NUMBER/VIDEO NUMBER</th>
                          <th>PRICE</th>
                          <th>TAX(3%)</th>
                          <th>TOTAL AMOUNT</th>
                          <th>STATUS</th>
                        </tr>
                      </thead>
                      <tbody>
                        {userData &&
                          userData.map(
                            (res) =>
                              res &&
                              res.cartdetails &&
                              res.cartdetails.map((resp) => (
                                <tr>
                                  <td>{res?.order_id}</td>
                                  <td>{resp?.category}</td>
                                  {resp?.category === "image" && (
                                    <td>
                                      {res?.image?.[0]?.itemName
                                        ? res?.image?.[0]?.itemName
                                        : "-"}
                                    </td>
                                  )}
                                  {resp?.category === "video" && (
                                    <td>
                                      {res?.video?.[0]?.itemName
                                        ? res?.video?.[0]?.itemName
                                        : "-"}
                                    </td>
                                  )}
                                  {resp?.category === "audio" && (
                                    <td>
                                      {res?.audio?.[0]?.itemName
                                        ? res?.audio?.[0]?.itemName
                                        : "-"}
                                    </td>
                                  )}
                                  <td>
                                    {resp?.quantity ? resp?.quantity : "-"}
                                  </td>
                                  <td>
                                    {res?.createdAt
                                      ? moment(res?.createdAt)
                                          .utc()
                                          .format("YYYY-MM-DD")
                                      : "-"}
                                  </td>
                                  {resp?.category === "image" && (
                                    <td>
                                      {res?.image?.[0]?.walletNumber
                                        ? res?.image?.[0]?.walletNumber
                                        : "-"}
                                    </td>
                                  )}
                                  {/* {resp?.category === "video" && <td>{res?.video?.[0]?.walletNumber ? res?.video?.[0]?.walletNumber : "-"}</td>} */}
                                  {resp?.category === "video" && (
                                    <td>
                                      {res?.video?.[0]?.video_no
                                        ? res?.video?.[0]?.video_no
                                        : "-"}
                                    </td>
                                  )}
                                  {resp?.category === "audio" && (
                                    <td>
                                      {res?.audio?.[0]?.audio_no
                                        ? res?.audio?.[0]?.audio_no
                                        : "-"}
                                    </td>
                                  )}

                                  <td>
                                    {res?.cartdetails?.[0]?.total_amount
                                      ? res?.cartdetails?.[0]?.total_amount /
                                        1.03
                                      : "-"}
                                  </td>

                                  <td>
                                    {res?.cartdetails?.[0]?.total_amount
                                      ? (() => {
                                          const amount =
                                            res.cartdetails[0].total_amount;
                                          const discountedAmount =
                                            amount - amount / 1.03;
                                          return discountedAmount % 1 !== 0
                                            ? discountedAmount.toFixed(2)
                                            : discountedAmount;
                                        })()
                                      : "-"}
                                  </td>

                                  {/* {resp?.category === "video" && (
                                    <td>
                                      {res?.video?.[0]?.price
                                        ? res?.cartdetails?.[0]?.total_amount -
                                          res?.video?.[0]?.price
                                        : "-"}
                                    </td>
                                  )}
                                  {resp?.category === "audio" && (
                                    <td>
                                      {res?.audio?.[0]?.price
                                        ? res?.cartdetails?.[0]?.total_amount -
                                          res?.audio?.[0]?.price
                                        : "-"}
                                    </td>
                                  )} */}

                                  <td>
                                    {res?.cartdetails?.[0]?.total_amount
                                      ? res?.cartdetails?.[0]?.total_amount
                                      : "-"}
                                  </td>

                                  <td>
                                    {res.status === 0
                                      ? "Order Placed"
                                      : "Delivered"}
                                  </td>
                                </tr>
                              ))
                          )}
                      </tbody>
                    </table>
                  ) : (
                    <div className="no-data flt">
                      <div className="nodata_img flt">
                        <img src={nodata} />
                      </div>
                      <div className="data_text flt">No Orders Found</div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {show === 0 && (
              <div className="paginationdiv flt">
                <ReactPaginate
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={pageCount}
                  currentPage={current}
                  showFirstLastPages={true}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={changeCurrentPage}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                  // forcePage={current - 1}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default MyAccount;
