import "./ImageList.css";
import noImage from "../../../assets/images/defaultimg.png";
import noAudio from "../../../assets/images/audio-default.png";
import noVideo from "../../../assets/images/defaultvideo.png";
import audiotop from "../../../assets/images/audiotop.png";
import useImageListHook from "./useImageListHook";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import loader from "../../../assets/images/logo.png";
import Header from "../RRCHeader";
import image from "../../../assets/images/image.png";
import audioimg from "../../../assets/images/audioimg.png";
import video from "../../../assets/images/video.png";
import CustomDropDown from "../RRCCustomDropDown";
import { alertActions, userLogout } from "../../../actions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import close_pop from "../../../assets/images/close-pop.png";
import { commaValidation } from "../../../utility";
import audioImg from "../../../assets/images/audio-default.png";
import $ from "jquery";
import nodata from "../../../assets/images/nodata.png";

const ImageListPage = (tokenExpired) => {
  const [datatype, setDatatype] = useState("");
  const {
    listRes,
    changeMediaHandler,
    media,
    changeSearchHandler,
    setSearch,
    search,
    changeCurrentPage,
    page,
    loading,
    types,
    category,
    listDisplay,
    handleRemoveCart,
    handleAddCart,
    showModal,
    closeModal,
    handleModal,
    cart,
    cartDetail,
    tax,
  } = useImageListHook();
  const dispatch = useDispatch();

  let history = useNavigate();
  useEffect(() => {
    if (tokenExpired.tokenExpired) {
      setTimeout(() => {
        dispatch(alertActions.error("Authentication error..Please login"));
      }, 50);

      dispatch(userLogout());
      history("/login");
    }
  }, [tokenExpired.tokenExpired]);
  $(".more_info").click(function () {
    var $title = $(this).find(".title");
    if (!$title.length) {
      $(this).append(
        '<span class="title">' + $(this).attr("title") + "</span>"
      );
    } else {
      $title.remove();
    }
  });

  function resetFilter() {
    // alert(datatype)
    setSearch("");
    listDisplay(category, "", 1, datatype, "");
  }

  useEffect(() => {}, [cartDetail?.data?.data]);

  const emptypg = () => {
    return (
      <div className="cartpg flt">
        <img src={loader} alt="rrc" />
      </div>
    );
  };
  const options = [
    {
      value: "image",
      label: (
        <span className="option-sec">
          <img src={image}></img>Image
        </span>
      ),
    },
    {
      value: "audio",
      label: (
        <span className="option-sec">
          <img src={audioimg}></img>Audio
        </span>
      ),
    },
    {
      value: "video",
      label: (
        <span className="option-sec">
          <img src={video}></img>Video
        </span>
      ),
    },
  ];

  const customHover = (index) => {
    var btnCls = document.getElementById("vw-dtl-btn-" + index);
    var divCls = document.getElementById("detail-sec-item-" + index);
    btnCls.className += " show";
    divCls.className += " show";
  };

  const customBlur = (index) => {
    var btnCls = document.getElementById("vw-dtl-btn-" + index);
    var divCls = document.getElementById("detail-sec-item-" + index);
    btnCls.classList.remove("show");
    divCls.classList.remove("show");
  };

  const imageListUI = (data) => {
    return data.map((e, index) => {
      return (
        <div className="col-sm-4">
          {e.mediatype === "image" && (
            <div className="image-div-list">
              <div
                className={`detail-sec-item ${e.mediatype}`}
                id={`detail-sec-item-${index}`}
                // onMouseEnter={() => customHover(index)}
                onMouseDown={() => customHover(index)}
                onMouseLeave={() => customBlur(index)}
              >
                <button
                  className="vw-dtl-btn vw-dtl-btns"
                  id={`vw-dtl-btn-${index}`}
                  onClick={() => handleModal(e._id, e.mediatype)}
                >
                  {" "}
                  View detail
                </button>
                {e.url || e.url != "" ? (
                  <span className="overlay">
                    {" "}
                    <img
                      className="image-sec-list-page"
                      src={`${process.env.REACT_APP_MEDIA_DOMAIN}${e.url}`}
                    ></img>
                  </span>
                ) : (
                  <span className="overlay">
                    <img className="image-sec" src={`${noImage}`} />
                  </span>
                )}
              </div>

              <div className="image-data-sec">
                <div className="image-data-name-sec">
                  <div className="display_price">
                    <div
                      title={e.itemName ? e.itemName : "-"}
                      className="image-data-name"
                    >
                      {e.itemName ? e.itemName : "-"}
                    </div>
                    <div className="image-data-count-sec">
                      <p className="image-data-count">
                        {e.numberOfDocs ? e?.numberOfDocs + " Photos" : ""}
                      </p>
                    </div>
                  </div>

                  <div className="display_btn">
                    {e?.price ? (
                      <div className="image-data-amount">
                        &#8377;{commaValidation(e?.price ? e?.price : 0)}
                      </div>
                    ) : (
                      <div className="image-data-amount">0</div>
                    )}

                    {e.carts.length == 0 ? (
                      <div className="cart">
                        <button
                          onClick={() =>
                            handleAddCart(
                              e._id,
                              e.mediatype,
                              e.numberOfDocs,
                              e.price + (e.price * listRes?.data?.tax) / 100
                            )
                          }
                          className="add-to-cart"
                        >
                          Add
                        </button>
                      </div>
                    ) : (
                      <div className="cart">
                        <button
                          onClick={() => handleRemoveCart(e.carts[0]._id)}
                          className="add-to-cart"
                        >
                          Remove
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {e.mediatype === "audio" && (
            <div className="image-div-list">
              <div
                className={`detail-sec-item ${e.mediatype}`}
                id={`detail-sec-item-${index}`}
                // onMouseEnter={() => customHover(index)}
                onMouseDown={() => customHover(index)}
                onMouseLeave={() => customBlur(index)}
              >
                <button
                  className="vw-dtl-btn vw-dtl-btns"
                  id={`vw-dtl-btn-${index}`}
                  onClick={() => handleModal(e._id, e.mediatype)}
                >
                  {" "}
                  View detail
                </button>
                {e.url || e.url != "" ? (
                  <div className="audio_main">
                    <img src={audioImg} alt="images" />
                    <audio
                      src={`${process.env.REACT_APP_MEDIA_DOMAIN}${e.url}`}
                      controls
                    >
                      <source
                        src={`${process.env.REACT_APP_MEDIA_DOMAIN}${e.url}`}
                        type="audio/ogg"
                      />
                    </audio>
                  </div>
                ) : (
                  <>
                    <img className="image-sec" src={`${noAudio}`} />
                    <audio
                      src={`${process.env.REACT_APP_MEDIA_DOMAIN}${e?.url}`}
                      controls
                    >
                      <source
                        src={`${process.env.REACT_APP_MEDIA_DOMAIN}${e?.url}`}
                        type="audio/ogg"
                      />
                    </audio>
                  </>
                )}
              </div>

              <div className="image-data-sec">
                <div className="image-data-name-sec">
                  <div className="display_price">
                    <div
                      title={e.itemName ? e.itemName : ""}
                      className="image-data-name"
                    >
                      {e.itemName ? e.itemName : ""}
                    </div>
                    <div className="image-data-count-sec">
                      <p className="image-data-count">
                        {e.numberOfDocs
                          ? e?.numberOfDocs + " Audios"
                          : "0 Audios"}
                      </p>
                    </div>
                  </div>

                  <div className="display_btn">
                    {e?.price ? (
                      <div className="image-data-amount">
                        &#8377;{commaValidation(e?.price ? e?.price : 0)}
                      </div>
                    ) : (
                      <div className="image-data-amount">0</div>
                    )}
                    {e.carts.length == 0 ? (
                      <div className="cart">
                        <button
                          onClick={() =>
                            handleAddCart(
                              e._id,
                              e.mediatype,
                              e.numberOfDocs,
                              e.price + (e.price * listRes?.data?.tax) / 100
                            )
                          }
                          className="add-to-cart"
                        >
                          Add
                        </button>
                      </div>
                    ) : (
                      <div className="cart">
                        <button
                          onClick={() => handleRemoveCart(e.carts[0]._id)}
                          className="add-to-cart"
                        >
                          Remove
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {e.mediatype === "video" && (
            <div
              className="image-div-list"
              onMouseDown={() => customHover(index)}
              onMouseLeave={() => customBlur(index)}
            >
              <div
                className={`detail-sec-item1 ${e.mediatype}`}
                id={`detail-sec-item-${index}`}
                // onMouseEnter={() => customHover(index)}
              >
                <button
                  className="vw-dtl-btn1 vw-dtl-btns1"
                  id={`vw-dtl-btn-${index}`}
                  onClick={() => handleModal(e._id, e.mediatype)}
                  onMouseLeave={() => customBlur(index)}
                >
                  {" "}
                  View detail
                </button>
                {e.url || e.url != "" ? (
                  <span className="overlay">
                    <video
                      className="image-sec"
                      src={`${process.env.REACT_APP_MEDIA_DOMAIN}${e.url}`}
                      controls
                      autoplay
                    >
                      <source
                        src={`${process.env.REACT_APP_MEDIA_DOMAIN}${e.url}`}
                        type="video/mp4"
                      />
                    </video>
                  </span>
                ) : (
                  <>
                    <span className="overlay">
                      <img className="video_mobile" src={`${noVideo}`} />
                    </span>
                  </>
                )}
              </div>

              {/* <div className="image-data-sec">
                  <div className="image-data-name-sec">
                    <p className="image-data-name">
                      {e.itemName ? e.itemName : ""}
                    </p>
                    {e.price ? (
                      <p className="image-data-amount">
                        &#8377;{commaValidation(e.price ? e.price : "")}
                      </p>
                    ) : null}

                    <div>
                      <button
                        onClick={() => handleAddCart(e._id, e.main_cat_type, e.numberOfDoc)}
                        className="rm-cart-button"
                      >
                        Add to cart
                      </button>
                    </div>
                    <div>
                     
                    </div>
                  </div>

                  <div className="image-data-count-sec">
                    <p className="image-data-count">
                      {e.numberOfDocs ? e?.numberOfDocs + " Videos" : ""}
                    </p>
                  </div>
                </div> */}

              <div className="image-data-sec">
                <div className="image-data-name-sec">
                  <div className="display_price">
                    <div
                      title={e.itemName ? e.itemName : ""}
                      className="image-data-name"
                    >
                      {e.itemName && e.itemName != "" ? e.itemName : "-"}
                    </div>
                    <div className="image-data-count-sec">
                      <p className="image-data-count">
                        {e.numberOfDocs ? e?.numberOfDocs + " Videos" : ""}
                      </p>
                    </div>
                  </div>
                  <div className="display_btn">
                    {e?.price ? (
                      <div className="image-data-amount">
                        &#8377;{commaValidation(e?.price ? e?.price : 0)}
                      </div>
                    ) : (
                      <div className="image-data-amount">0</div>
                    )}
                    {e.carts.length == 0 && (
                      <div className="cart">
                        <button
                          onClick={() =>
                            handleAddCart(
                              e._id,
                              e.mediatype,
                              e.numberOfDocs,
                              e.price + (e.price * listRes?.data?.tax) / 100
                            )
                          }
                          className="add-to-cart"
                        >
                          Add
                        </button>
                      </div>
                    )}
                    {e.carts.length !== 0 && (
                      <div className="cart">
                        <button
                          onClick={() => handleRemoveCart(e.carts[0]._id)}
                          className="add-to-cart"
                        >
                          Remove
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      );
    });
  };

  const selectType = (evt, type, page) => {
    setDatatype(type && type !== undefined ? type : "");
    var i, tablinks;
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    evt.currentTarget.className += " active";

    listDisplay(media, search, page, type);
  };

  useEffect(() => {
    if (listRes?.data?.docs) console.log("here");
  }, [listRes?.data?.docs]);

  //modal
  const detailModal = () => {
    // var footrClass = document.getElementById("footer-sec");
    // footrClass.className += " hide";
    return (
      <div class="modalPop" id="enquiryModal">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">
              <div className="close-modal-sec">
                <div className="modal-detail-head">DETAILS</div>
                <button
                  type="button"
                  className="close pop-up-close"
                  onClick={closeModal}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div className="pop-up-details-div">
                {cartDetail?.data?.data?.mediatype === "image" && (
                  <div className="image-div">
                    {cartDetail?.data?.data?.url ||
                    cartDetail?.data?.data?.url != "" ? (
                      <img
                        className="image-sec-list"
                        src={`${process.env.REACT_APP_MEDIA_DOMAIN}${cartDetail?.data?.data?.url}`}
                      />
                    ) : (
                      <img className="image-sec" src={`${noImage}`} />
                    )}
                  </div>
                )}
                {cartDetail?.data?.data?.mediatype === "audio" && (
                  <div className="image-div">
                    {cartDetail?.data?.data?.url ||
                    cartDetail?.data?.data?.url != "" ? (
                      <div className="audio-img-sec-popup">
                        <img src={audioImg} />
                        <audio controls>
                          <source
                            src={`${process.env.REACT_APP_MEDIA_DOMAIN}${cartDetail?.data?.data?.url}`}
                            type="audio/mpeg"
                          />
                        </audio>
                      </div>
                    ) : (
                      <img className="image-sec" src={`${noAudio}`} />
                    )}
                  </div>
                )}
                {cartDetail?.data?.data?.mediatype === "video" && (
                  <div className="image-div">
                    {cartDetail?.data?.data?.url ||
                    cartDetail?.data?.data?.url != "" ? (
                      <video width="100%" controls>
                        <source
                          src={`${process.env.REACT_APP_MEDIA_DOMAIN}${cartDetail?.data?.data?.url}`}
                          type="video/mp4"
                        />
                      </video>
                    ) : (
                      <img className="image-sec" src={`${noVideo}`} />
                    )}
                  </div>
                )}

                <div className="content-div">
                  <p className="item-title">
                    {`Name : ${
                      cartDetail?.data?.data?.itemName
                        ? cartDetail?.data?.data?.itemName
                        : "No Image"
                    }`}
                    <span>
                      ({cartDetail?.data?.data?.numberOfDocs}{" "}
                      {`${cartDetail?.data?.data?.mediatype}`})
                    </span>
                  </p>
                  <p className="item-date">{`Date : ${
                    cartDetail?.data?.data?.date
                      ? cartDetail?.data?.data?.date
                      : cartDetail?.data?.data?.recordingdate
                  }`}</p>
                  <p className="item-date">
                    {`Category: ${cartDetail?.data?.data?.category}`}
                  </p>

                  <p className="item-date">{`Place : ${
                    cartDetail?.data?.data?.place
                      ? cartDetail?.data?.data?.place
                      : cartDetail?.data?.data?.recordingplace
                  }`}</p>
                  <p className="item-date">{`Type : ${cartDetail?.data?.data?.type}`}</p>
                  {cartDetail?.data?.data?.walletNumber ? (
                    <p className="item-date">
                      {`Wallet Number : ${cartDetail?.data?.data?.walletNumber}`}
                    </p>
                  ) : cartDetail?.data?.data?.video_no ? (
                    <p className="item-date">
                      {`Video Number : ${cartDetail?.data?.data?.video_no}`}
                    </p>
                  ) : (
                    <p className="item-date">
                      {`Audio Number : ${cartDetail?.data?.data?.audio_no}`}
                    </p>
                  )}
                  <p className="item-price">
                    {`Price : ₹${commaValidation(
                      cartDetail?.data?.data?.price
                    )}`}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className={showModal ? "list-outer-sec inactive" : "list-outer-sec"}>
      <Header cart={cart} />
      {showModal && detailModal()}
      {loading === "notempty" ? (
        <>
          <div
            className={showModal ? "list-main-sec inactive" : "list-main-sec"}
          >
            {/* <div className='list-filter-sec'>
              <div className='custom-drop-down button_image'>
                <CustomDropDown
                  defaultValue={options[0]}
                  value={media}
                  onChange={changeMediaHandler}
                  options={options}
                  classNamePrefix='select'
                />
                <button>
                  <img src={image} />
                  Image
                </button>
                <button>
                  <img src={audioimg} />
                  Video
                </button>
                <button>
                  <img src={video} />
                  Audio
                </button>
              </div>


              <div className="list-filter-sec-div">
               
                <input
                  type='text'
                  placeholder='Search '
                  name='search'
                  value={search}
                  className='filter-search'
                  onChange={changeSearchHandler}
                />
                <button className='reset-btn-filter' onClick={() => resetFilter()}>Reset</button>
              </div>
            </div> */}
            <div className="search_main flt">
              <div className="row">
                <div className="col-sm-4">
                  <div className="search_left flt">
                    <button
                      className={category === "image" ? "imagebutton" : ""}
                      onClick={changeMediaHandler}
                      name="image"
                      value="image"
                    >
                      <img src={image} />
                      Image
                    </button>
                    <button
                      className={category === "audio" ? "imagebutton" : ""}
                      onClick={changeMediaHandler}
                      name="audio"
                      value="audio"
                    >
                      <img src={audioimg} />
                      Audio
                    </button>
                    <button
                      className={category === "video" ? "imagebutton" : ""}
                      onClick={changeMediaHandler}
                      name="video"
                      value="video"
                    >
                      <img src={video} />
                      Video
                    </button>
                  </div>
                </div>
                <div className="col-sm-8">
                  <div className="search_right flt">
                    <input
                      type="text"
                      placeholder="Search "
                      name="search"
                      value={search}
                      className="filter-search"
                      onChange={changeSearchHandler}
                    />
                    <div className="src_icon">
                      <i class="fas fa-search"></i>
                    </div>

                    {/* <button className='reset-btn-filter' onClick={() => resetFilter()}>Reset</button> */}
                  </div>
                </div>
              </div>
            </div>

            <div>
              {category === "image" ? (
                <div class="tab">
                  <button
                    className="tablinks active"
                    onClick={(e) => selectType(e, "", 1)}
                  >
                    All
                  </button>
                  <button
                    className="tablinks"
                    onClick={(e) => selectType(e, "Black and White", 1)}
                  >
                    Black & White
                  </button>
                  <button
                    className="tablinks"
                    onClick={(e) => selectType(e, "Colour", 1)}
                  >
                    Colour
                  </button>
                  <button
                    className="tablinks"
                    onClick={(e) => selectType(e, "Colour", 1)}
                  >
                    HD
                  </button>
                </div>
              ) : null}
            </div>

            <div className="main-div">
              <div className="row">
                {listRes?.data?.docs && listRes?.data?.docs.length !== 0 ? (
                  imageListUI(listRes?.data?.docs)
                ) : (
                  <div className="no-data flt">
                    <div className="nodata_img flt">
                      <img src={nodata} />
                    </div>
                    <div className="data_text flt">No Data Found</div>
                  </div>
                )}

                {listRes?.data?.docs && listRes?.data?.docs.length !== 0 && (
                  <div className="paginationdiv flt">
                    <ReactPaginate
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={listRes?.data?.totalPages}
                      currentPage={page}
                      showFirstLastPages={true}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={1}
                      onPageChange={changeCurrentPage}
                      forcePage={page - 1}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        emptypg()
      )}
    </div>
  );
};

export default ImageListPage;
