import "./home.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import yakshaganasecbanner from "../../../assets/images/yakshaganasecbanner.png";
import bhutakolaspritworship from "../../../assets/images/bhutakolaspritworship.png";
import veshagararudayactor from "../../../assets/images/veshagararudayactor.png";

import culturefirstone from "../../../assets/images/bcola1.png";
import culturefirsttwo from "../../../assets/images/bcola2.png";
import culturefirstthree from "../../../assets/images/bcola3.png";
import strypurps from "../../../assets/images/strypurps.png";
import srtypurpse from "../../../assets/images/srtypurpse.png";
import photo from "../../../assets/images/photo.png";
import footage from "../../../assets/images/footage.png";
import muic from "../../../assets/images/muic.png";
import secbannr from "../../../assets/images/secbannr.png";
import bttmimgone from "../../../assets/images/bttmimgone.png";
import bttmimgtwo from "../../../assets/images/bttmimgtwo.png";
import bttmimgthree from "../../../assets/images/bttmimgthree.png";
import bttmimgforth from "../../../assets/images/bttmimgforth.png";
import bttmimgfifth from "../../../assets/images/bttmimgfifth.png";
import bttmimgsixth from "../../../assets/images/bttmimgsixth.png";
import bttmimgseventh from "../../../assets/images/bttmimgseventh.png";
import bttmimgseight from "../../../assets/images/bttmimgseight.png";
import bttmimgnine from "../../../assets/images/bttmimgnine.png";
import bttmimgten from "../../../assets/images/bttmimgten.png";
import bttmimgelevn from "../../../assets/images/bttmimgelevn.png";
import bttmimgtwelw from "../../../assets/images/bttmimgtwelw.png";
import bttmimgthirtn from "../../../assets/images/bttmimgthirtn.png";
import bttmimgfortn from "../../../assets/images/bttmimgfortn.png";
import bttmimgfifteen from "../../../assets/images/bttmimgfifteen.png";
import bttmimgsixtn from "../../../assets/images/bttmimgsixtn.png";
import bttmimgsevntn from "../../../assets/images/bttmimgsevntn.png";
import bttmimgeihtn from "../../../assets/images/bttmimgeihtn.png";
import bttmimgnintn from "../../../assets/images/bttmimgnintn.png";
import bttmimgntwenty from "../../../assets/images/bttmimgntwenty.png";
import bttmimgntwentyone from "../../../assets/images/bttmimgntwentyone.png";
import bttmimgntwentytwo from "../../../assets/images/bttmimgntwentytwo.png";
import bttmimgntwentythr from "../../../assets/images/bttmimgntwentythr.png";
import bttmimgntwentyfour from "../../../assets/images/bttmimgntwentyfour.png";
import rrcbannerimg from "../../../assets/images/rrcbannerimg.png";
import rrcbannerimg1 from "../../../assets/images/kola.png";
import bannermiddleimg from "../../../assets/images/bannermiddleimg.png";
import aadivasi11 from "../../../assets/images/aadivasi1.png";
import aadivasi12 from "../../../assets/images/aadivasi2.png";
import aadivasi13 from "../../../assets/images/aadivasi3.png";
import folk1 from "../../../assets/images/folk1.png";
import folk2 from "../../../assets/images/folk2.png";
import folk3 from "../../../assets/images/folk3.png";
import Header from "../RRCHeader";
import { useNavigate } from "react-router-dom";

const HomePage = () => {
  let history = useNavigate();

  const photogalery = () => {
    history("/list");
  };
  var settings = {
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 1500,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Header />
      <div className="main-page flt">
        <div className="slder-div flt">
          <div className="container">
            <div className="row">
              <Slider {...settings}>
                <div className="bannermaindiv">
                  <div className="rrc-bannerimg">
                    <div>
                      <img src={rrcbannerimg} />
                    </div>

                    <div>
                      <img src={bannermiddleimg} />
                    </div>
                  </div>
                  <div className="banner-txt flt">
                    <div className="bannr-dividr flt">
                      <hr />
                    </div>
                    <div className="bannr-maintext flt">Yakshagana</div>
                    <div className="bannr-text flt">
                      Yakshagana is a traditional theater, developed in Dakshina
                      Kannada, Udupi, Uttara Kannada, Shimoga and western parts
                      of Chikmagalur districts, in the state of Karnataka and in
                      Kasargod district in Kerala that combines dance, music,
                      dialogue, costume, make-up, and stage techniques with a
                      unique style and form.
                    </div>
                  </div>
                </div>

                <div className="bannermaindiv">
                  <div className="rrc-bannerimg">
                    <div>
                      <img src={rrcbannerimg1} />
                    </div>

                    <div>
                      <img src={bannermiddleimg} />
                    </div>
                  </div>
                  <div className="banner-txt flt">
                    <div className="bannr-dividr flt">
                      <hr />
                    </div>
                    <div className="bannr-maintext flt">Bhutha Kola</div>
                    <div className="bannr-text flt">
                      Bhutha Kola is an Animist form of Spirit worship from the
                      coastal districts of Tulu Nadu and some parts of malenadu
                      of Karnataka and Kasargod in Northern Kerala, India. The
                      dance is highly stylized and held in honor of the local
                      deities worshiped by the Tulu speaking population. It has
                      influenced Yakshagana folk theatre. Bhutha Kola is closely
                      related to Theyyam from neighbouring Malayalam speaking
                      populations.
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>

        <div className="yakshagana-maindiv flt">
          <div className="container">
            <div className="row">
              <div className="col-sm-4">
                <div className="yakshagana-div flt">
                  <div className="yakshagana-text flt">
                    A trip to the coastal belt would be incomplete without
                    watching the Yakshagana – an elaborate dance-drama
                    performance unique to Karnataka. It is a rare combination of
                    dance, music, song, scholarly dialogues and colourful
                    costumes. A celestial world unfolds before the audience, as
                    loud singing and drumming form a backdrop to dancers clad in
                    striking costumes.
                  </div>
                  <hr className="hrdividr flt" />
                  <div className="yakshagana-sectext flt">
                    Yakshagana,Drama and Music.
                  </div>
                </div>
              </div>

              <div className="col-sm-4">
                <div className="yakshagana-img">
                  <div className="yakshagana-firsttxt">
                    {" "}
                    YAKSHAGANA TENKUTITTU{" "}
                  </div>
                  <div className="yakshagana-imgf">
                    {" "}
                    <img src={yakshaganasecbanner} />{" "}
                  </div>
                </div>
              </div>

              <div className="col-sm-4">
                <div className="yakshagana-toptop">
                  <div className="yakshagana-sectxt">bhutharadane</div>
                  <div className="yakshagana-righttop">
                    {" "}
                    <img src={bhutakolaspritworship} />{" "}
                  </div>
                </div>

                <div className="yakshagana-topbtm">
                  <div className="yakshagana-thirdtxt">Veshagararu</div>
                  <div className="yakshagana-rightbttm ">
                    {" "}
                    <img src={veshagararudayactor} />{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="cultural-maindiv flt">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="cultural-text flt">
                  Art that is part of a culture
                </div>
              </div>
            </div>
            <div className="cultural-img-maindiv">
              <div className="image-main-div">
                <div className="cultr-div flt">
                  <div className="cultr-imgright flt">
                    <div className="cultr-img">
                      <div className="cultr-imgone">
                        <img src={aadivasi11} />
                      </div>
                      <div className="cultr-imgtwo">
                        <img src={aadivasi12} />
                      </div>
                    </div>
                    <div className="cultr-imgthree">
                      <img src={aadivasi13} />
                    </div>
                  </div>
                  <div className="cultur-text">
                    {" "}
                    ADIVASI FESTIVAL MAHARASTRA{" "}
                  </div>
                </div>
              </div>

              <div className="image-main-div">
                <div className="cultr-div flt">
                  <div className="cultr-imgright flt">
                    <div className="cultr-img">
                      <div className="cultr-imgone">
                        <img src={folk1} />
                      </div>
                      <div className="cultr-imgtwo">
                        <img src={folk2} />
                      </div>
                    </div>
                    <div className="cultr-imgthree">
                      <img src={folk3} />
                    </div>
                  </div>
                  <div className="cultur-text">FOLK DANCE</div>
                </div>
              </div>

              <div className="image-main-div">
                <div className="cultr-div flt">
                  <div className="cultr-imgright flt">
                    <div className="cultr-img">
                      <div className="cultr-imgone">
                        <img src={culturefirstone} />
                      </div>
                      <div className="cultr-imgtwo">
                        <img src={culturefirsttwo} />
                      </div>
                    </div>
                    <div className="cultr-imgthree">
                      <img src={culturefirstthree} />
                    </div>
                  </div>
                  <div className="cultur-text">BHUTHA KOLA</div>
                </div>
              </div>

              <div className="image-main-div">
                <div className="cultr-div flt">
                  <div className="cultr-imgright flt">
                    <div className="cultr-img">
                      <div className="cultr-imgone">
                        <img src={aadivasi11} />
                      </div>
                      <div className="cultr-imgtwo">
                        <img src={aadivasi12} />
                      </div>
                    </div>
                    <div className="cultr-imgthree">
                      <img src={aadivasi13} />
                    </div>
                  </div>
                  <div className="cultur-text">ADIVASI FESTIVAL MAHARASTRA</div>
                </div>
              </div>
            </div>

            <div className="row all-coctn-div">
              <a className="all-collctn" href="/gallery">
                Go to Gallery
              </a>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-sm-6">
                <div className="strypurps-main flt">
                  <div className="stry-purpseimg flt">
                    {" "}
                    <img src={strypurps} />{" "}
                  </div>
                  <div className="stry-purpshead flt">About RRC</div>
                  <div className="stry-purpsetext flt">
                    Regional Resources Centre for Folk Performing Arts was built
                    with the help of the grants offered by the Ford Foundation.
                    The saga of the RRC marks the culmination of international
                    recognition that this pioneering institution of learning
                    acquired. The origin of this recognition was by way of an
                    invitation extended to Prof.K.S.Haridasa Bhat in 1962-63 to
                    attend a 6 month course in ISVE, Italy. The invitation from
                    the ICCR to visit foreign countries like Hong Kong leading
                    the Folk Dance troupes was the beginning of a series of
                    foreign trips.
                  </div>
                  <hr className="stry-purpsedivdr flt" />
                  <div className="stry-purpsbht flt">
                    Prof . K. S. Haridas Bhat
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="strypurps-rightimg flt">
                  <img src={srtypurpse} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="phtfootgmus-maindiv flt">
          <div className="stry-purpshead colc flt">All Collections</div>
          <div className="phtftgmsc-div flt">
            <div className="container">
              <div className="row">
                <div className="col-sm-4">
                  <div className="phtftgmus-img flt">
                    <div className="photo-img flt">
                      <img src={photo} />
                    </div>
                    <div className="view_more">
                      <span onClick={() => photogalery()}>Take me here</span>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="phtftgmus-img flt">
                    <div className="footage-img flt">
                      <img src={footage} />
                    </div>
                    <div className="view_more">
                      <span onClick={() => photogalery()}>Take me here</span>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="phtftgmus-img flt">
                    <div className="music-img flt">
                      <img src={muic} />
                    </div>
                    <div className="view_more">
                      <span onClick={() => photogalery()}>Take me here</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="visiting-main flt">
          <div className="container">
            <div className="row">
              <div className="col-sm-7">
                <div className="visiting-img flt">
                  <img src={secbannr} />
                </div>
              </div>
              <div className="col-sm-5">
                <div className="visiting-text flt">
                  Dr. Peter Claus helped the RRC to launch a series of
                  International Workshops in Folklore studies(1988-89) in which
                  eminent folklorists of the world participated as resource
                  persons. A book on Indian Folkloristics by Dr. Peter Claus and
                  Frank Korrum published by the RRC was the product of the
                  workshop series. The Indo Finnish Project carried out in the
                  precints of Dharmasthala and Belthangady Taluks under the
                  Auspices of RRC.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bottom-mainbanner flt">
          <div className="container">
            <div className="bottm-bannertext flt">REGIONAL RESEARCH CENTRE</div>
            <div className="botm-bannergallry flt">
              <ul className="bottmrow-gallry flt">
                <li>
                  <img src={bttmimgone} />
                </li>
                <li>
                  <img src={bttmimgtwo} />
                </li>
                <li>
                  <img src={bttmimgthree} />
                </li>
                <li>
                  <img src={bttmimgforth} />
                </li>
                <li>
                  <img src={bttmimgfifth} />
                </li>
                <li>
                  <img src={bttmimgsixth} />
                </li>
                <li>
                  <img src={bttmimgseventh} />
                </li>
                <li>
                  <img src={bttmimgseight} />
                </li>
              </ul>
              <ul className="bottmrow-gallry flt">
                <li>
                  <img src={bttmimgnine} />
                </li>
                <li>
                  <img src={bttmimgten} />
                </li>
                <li>
                  <img src={bttmimgelevn} />
                </li>
                <li>
                  <img src={bttmimgtwelw} />
                </li>
                <li>
                  <img src={bttmimgthirtn} />
                </li>
                <li>
                  <img src={bttmimgfortn} />
                </li>
                <li>
                  <img src={bttmimgfifteen} />
                </li>
                <li>
                  <img src={bttmimgsixtn} />
                </li>
              </ul>
              <ul className="bottmrow-gallry flt">
                <li>
                  <img src={bttmimgsevntn} />
                </li>
                <li>
                  <img src={bttmimgeihtn} />
                </li>
                <li>
                  <img src={bttmimgnintn} />
                </li>
                <li>
                  <img src={bttmimgntwenty} />
                </li>
                <li>
                  <img src={bttmimgntwentyone} />
                </li>
                <li>
                  <img src={bttmimgntwentytwo} />
                </li>
                <li>
                  <img src={bttmimgntwentythr} />
                </li>
                <li>
                  <img src={bttmimgntwentyfour} />
                </li>
              </ul>
              <ul className="bottmrow-gallry flt">
                <li>
                  <img src={bttmimgone} />
                </li>
                <li>
                  <img src={bttmimgtwo} />
                </li>
                <li>
                  <img src={bttmimgthree} />
                </li>
                <li>
                  <img src={bttmimgforth} />
                </li>
                <li>
                  <img src={bttmimgfifth} />
                </li>
                <li>
                  <img src={bttmimgsixth} />
                </li>
                <li>
                  <img src={bttmimgseventh} />
                </li>
                <li>
                  <img src={bttmimgseight} />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default HomePage;
