import useConfirmPasswordHook from "./useConfirmPassword";
import Logo from "../../../assets/images/logo.png";
import "./ConfirmPassword.css";
import Header from "../RRCHeader";

const ConfirmPassword = () => {
  const {
    new_password,
    re_password,
    submitted,
    handleNewPasswordChange,
    handleRePasswordChange,
    handleSubmit,
  } = useConfirmPasswordHook();
  const confirmPassword = () => {
    return (
      <div>
        <Header />
        <div className="login_container flt">
          <div className="login_box">
            <div className="login_cont flt">
              <div style={{ padding: "2% 15%" }}>
                <img src={Logo} style={{ width: "100%" }} />
              </div>
              <div>
                <p className="sec-header">RESET YOUR PASSWORD</p>
                <p className="sec-sub-header">
                  Please enter your new password
                </p>
              </div>

              <div className="form-group flt">
                <input
                  type="password"
                  placeholder="Enter New Password"
                  name="new_password"
                  value={new_password}
                  autoComplete="off"
                  onChange={handleNewPasswordChange}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit(e);
                    }
                  }}
                  className={
                    submitted && !new_password
                      ? "input-sec is-invalid"
                      : "input-sec"
                  }
                />
                {submitted && !new_password && (
                  <div className="invalid-feedback">Password is required</div>
                )}
              </div>
              <div className="form-group flt">
                <input
                  type="password"
                  placeholder="Enter Confirm Password"
                  name="re_password"
                  value={re_password}
                  autoComplete="off"
                  onChange={handleRePasswordChange}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit(e);
                    }
                  }}
                  className={
                    submitted && !re_password
                      ? "input-sec is-invalid"
                      : "input-sec"
                  }
                />
                {!re_password && (
                  <div className="invalid-feedback">
                    Confirm password is required
                  </div>
                )}
              </div>

              <button className="login_btn flt" onClick={handleSubmit}>
                Submit
              </button>
            </div>
            <div className="image-sec-right">
              <div className="right-sec">
                <p className="right-sec-header">Welcome to RRC</p>
                <p className="right-sec-desc">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Mattis molestie amet blandit nisi. Morbi faucibus ut id tortor
                  semper eLorem ipsum dolor sit amet, consectetur adipiscing
                  elit. Mattis molestie amet blandit nisi. Morbi faucibus ut id
                  tortor semper e
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return <div>{confirmPassword()}</div>;
};
export default ConfirmPassword;
