import { useEffect, useState } from "react";
import { alertActions } from "../../../actions/alert.actions";
import { useNavigate } from "react-router-dom";
import { userLogin } from "../../../actions/index";
import { useDispatch } from "react-redux";

const useLoginHooks = () => {
  let history = useNavigate();
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    if (userData?.status?.code === 0) {
      setUsername("");
      setPassword("");
      setSubmitted(false);
      history("/");
      dispatch(alertActions.success(userData?.status?.message));
    } else {
      dispatch(alertActions.error(userData?.status?.message));
    }
  }, [userData]);

  function handleUsernameChange(e) {
    setUsername(e.target.value);
  }
  function handlePasswordChange(e) {
    setPassword(e.target.value);
  }

  function handleSubmit(e) {
    e.preventDefault();
    setSubmitted(true);
    if (username && password) {
      dispatch(userLogin(username, password))
        .then((response) => {
          localStorage.setItem("expiresIn", response?.data?.data?.expires_in);
          var today = new Date();
          localStorage.setItem("loginTime", today);
          setUserData(response?.data);
        })
        .catch((error) => {
          console.log("Errorreg", error);
        });
    }
  }

  return {
    handleUsernameChange,
    handlePasswordChange,
    username,
    password,
    submitted,
    handleSubmit,
  };
};
export default useLoginHooks;
