import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { alertActions } from "../../../actions";
import { UserService } from "../../../services";

const useListHook = () => {
  const [page, setPage] = useState(1);
  const [category, setCategory] = useState("image");
  const [listRes, setListRes] = useState({});
  const [search, setSearch] = useState("");
  const [media, setMedia] = useState("Image");
  const [loading, setLoading] = useState("loading");
  const [cart, setCart] = useState();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [showUrl, setShowUrl] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [storeKey, setStoreKey] = useState("");
  const [storeOrder, setStoreOrder] = useState("");
  // const openModal = () => {
  //   setShowModal(true);
  // };
  const closeModal = () => {
    var footerClass = document.getElementById("footer-sec");
    footerClass.classList.remove("hide");
    setShowModal(false);
    setShowUrl("");
  };

  useEffect(() => {
    if (category) {
      listDisplay(category, "", 1, "desc", "");
    } else {
      listDisplay("image", "", 1, "desc", "");
    }
  }, [cart]);
  //api call for list
  const listDisplay = (category, search, page, sortBy = "", sortKey = "") => {
    UserService.getDocs(page, category, search, sortBy, sortKey)
      .then((response) => {
        setListRes(response);
        setLoading("notempty");
      })
      .catch((error) => {
        console.log("Errorreg", error);
        setLoading("empty");
      });
  };
  // //api call for list
  // const listDisplay1 = (category, search, page, sortBy = "", sortKey = "") => {
  //   UserService.getDocs(page, category, search, sortBy, sortKey)
  //     .then((response) => {
  //       console.log("llll", response?.data?.result);
  //       setListRes(response);
  //       return response?.data?.result;

  //       // setLoading("notempty");
  //     })
  //     .catch((error) => {
  //       console.log("Errorreg", error);
  //       setLoading("empty");
  //     });
  // };
  //select media type
  function changeMediaHandler(e) {
    if (e.target.name === "audio") {
      setPage(1);
      setCategory("audio");
      listDisplay("audio", search, 1, "", "");
      setMedia(e.target.value);
    } else if (e.target.name === "video") {
      setPage(1);
      setCategory("video");
      listDisplay("video", search, 1, "", "");
      setMedia(e.target.value);
    } else if (e.target.name === "image"){
      setPage(1);
      setCategory("image");
      listDisplay("image", search, 1, "", "");
      setMedia(e.target.value);
    }
  }
  //remove Cart
  function handleRemoveCart(id) {
    UserService.removeCart(id)
      .then((response) => {
        if (response?.data?.status?.code === 0) {
          handleGetCart();
          dispatch(alertActions.success(response?.data?.status?.message));
        } else {
          dispatch(alertActions.error(response?.data?.status?.message));
        }
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  }
  //search
  function changeSearchHandler(e) {
    setSearch(e.target.value);
    setCategory(category);
    setPage(1);
    listDisplay(category, e.target.value, 1, "", "");
  }

  //reset filter
  function resetFilter() {
    setSearch("");
    setCategory(category);
    setPage(1);
    listDisplay(category, "", 1, "", "");
  }

  //add cart
  function handleAddCart(product_id, category, quantity, price) {
    setRefresh(!refresh);

    UserService.addCart(product_id, category, quantity, price)
      .then((response) => {
        if (response?.data?.status.code === 0) {
          handleGetCart();
          dispatch(alertActions.success(response?.data?.status?.message));
        } else {
          dispatch(alertActions.error(response?.data?.status?.message));
        }
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  }
  //get cart
  function handleGetCart() {
    UserService.getCart()
      .then((response) => {
        setCart(response?.data?.data?.count);
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  }
  //handle audio model
  function handleAudioModal(url) {
    setShowUrl(url);
    setShowModal(true);
  }
  //pagination
  const changeCurrentPage = (page) => {
    setPage(page.selected + 1);
    listDisplay(category, search, page.selected + 1, storeOrder, storeKey);
  };

  const sortData = (sortKey, sortBy) => {
    setStoreKey(sortKey);
    setStoreOrder(sortBy);
    listDisplay(category, search, page, sortBy, sortKey);
    return true;
  };
  return {
    listRes,
    changeMediaHandler,
    media,
    changeSearchHandler,
    resetFilter,
    search,
    changeCurrentPage,
    page,
    handleAddCart,
    loading,
    cart,
    category,
    sortData,
    showModal,
    closeModal,
    handleAudioModal,
    showUrl,
    handleRemoveCart,
    refresh,
  };
};
export default useListHook;
