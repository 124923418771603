import "./Blog.css";
import Header from "../RRCHeader";
import blog from "../../../assets/images/blog.png";

const Blogs = () => {
  return (
    <div className='main-page flt'>
      <Header />

      <div className='blog-sec-content'>
        <div className='blog-main flt'>
          <div className='container'>
            <div className='row'>
              <div className='col-sm-12'>
                <div className='blog-text flt'>Blogs</div>
              </div>
              <div className='blog-divdr flt '>
                <hr />
              </div>
            </div>
          </div>
        </div>

        <div className='blog-div flt'>
          <div className='container'>
            <div className='row'>
              <div className='col-sm-6'>
                <div className='blogimg flt'>
                  <img src={blog} />
                </div>
              </div>
              {/* <div className='col-sm-1'>
              <div className='blg-brdr flt'></div>
            </div> */}
              <div className='col-sm-6'>
                <div className='blog-textdiv flt'>
                  <div className='blog-date flt'>March 3, 2022</div>
                  <div className='blog-cnt-sec'>
                    <div className='blog-txthead flt'>Lorem ipsum</div>
                    <div className='blog-txtcontnt  flt'>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Mattis molestie amet blandit nisi. Morbi faucibus ut id
                      tortor semper eLorem ipsum dolor sit amet, consectetur
                      adipiscing elit. Mattis molestie amet blandit nisi. Morbi
                      faucibus ut id tortor semper e
                    </div>

                  </div>
                  <div className='blog-bttn flt'>
                    <button>READ MORE</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='blog-div flt'>
          <div className='container'>
            <div className='row'>
              <div className='col-sm-6'>
                <div className='blogimg flt'>
                  <img src={blog} />
                </div>
              </div>

              <div className='col-sm-6'>
                <div className='blog-textdiv flt'>
                  <div className='blog-date flt'>March 3, 2022</div>
                  <div className='blog-cnt-sec'>
                    <div className='blog-txthead flt'>Lorem ipsum</div>
                    <div className='blog-txtcontnt  flt'>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Mattis molestie amet blandit nisi. Morbi faucibus ut id
                      tortor semper eLorem ipsum dolor sit amet, consectetur
                      adipiscing elit. Mattis molestie amet blandit nisi. Morbi
                      faucibus ut id tortor semper e
                    </div>
                  </div>
                  <div className='blog-bttn flt'>
                    <button>READ MORE</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Blogs;
