import useLoginHooks from "./useLoginHooks";
import Logo from "../../../assets/images/logo.png";
import "./Login.css";
import Header from "../RRCHeader";

const Login = () => {
  const {
    handleUsernameChange,
    handlePasswordChange,
    username,
    password,
    submitted,
    handleSubmit,
  } = useLoginHooks();
  const logMain = () => {
    return (
      <div>
        <Header />
        <div className="login_container flt">
          <div className="login_box">
            <div className="login_cont flt">
              <div className="loginimg flt">
                <img src={Logo} />
              </div>
              <div>
                <p className="sec-header">Log in to Your Account</p>
              </div>

              <div className="form-group flt">
                <input
                  type="text"
                  placeholder="Enter Your Phone Number"
                  id="username"
                  name="username"
                  value={username}
                  onChange={handleUsernameChange}
                  autoComplete="off"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit(e);
                    }
                  }}
                  className={
                    submitted && !username
                      ? "input-sec is-invalid"
                      : "input-sec"
                  }
                />
                {submitted && !username && (
                  <div className="invalid-feedback">Phone number is required</div>
                )}
              </div>

              <div className="form-group flt">
                <input
                  type="password"
                  placeholder="Enter Password"
                  id="password"
                  name="password"
                  value={password}
                  autoComplete="off"
                  onChange={handlePasswordChange}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit(e);
                    }
                  }}
                  className={
                    submitted && !password
                      ? "input-sec is-invalid"
                      : "input-sec"
                  }
                />
                {submitted && !password && (
                  <div className="invalid-feedback">Password is required</div>
                )}
              </div>
              <div className="forgot-pass-sec">
                {/* <input type='checkbox' value='' />Rember me */}

                <a href="/forgot-password">
                  <button className="forgot-password">Forgot Password</button>
                </a>
              </div>
              <button className="login_btn flt" onClick={handleSubmit}>
                Login now
              </button>

              <div className="reg-sec">
                <p className="reg-sec-content">
                  Don't have an account ?{" "}
                  <a href="/registration">register here</a>
                </p>
              </div>
            </div>
            <div className="image-sec-right">
              <div className="right-sec">
                <p className="right-sec-header">Welcome to RRC</p>
                <p className="right-sec-desc">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Mattis molestie amet blandit nisi. Morbi faucibus ut id tortor
                  semper eLorem ipsum dolor sit amet, consectetur adipiscing
                  elit. Mattis molestie amet blandit nisi. Morbi faucibus ut id
                  tortor semper e
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return <div>{logMain()}</div>;
};
export default Login;
