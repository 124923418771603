import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { alertActions } from "../../../actions";
import { useNavigate } from "react-router-dom";
import { registration } from "../../../actions/UserAction";

const useRegistrationHooks = () => {
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = useState(false);
  const [registerData, setRegisterData] = useState({});
  const [name, setName] = useState("");
  const [mobile_no, setMobileNo] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [re_password, setRePassword] = useState("");
  let history = useNavigate();

  useEffect(() => {
    if (registerData?.status?.code === 0) {
      setName("");
      setMobileNo("");
      setEmail("");
      setPassword("");
      setRePassword("");
      history("/");
      setSubmitted(false);
      dispatch(alertActions.success(registerData?.status?.message));
    } else {
      dispatch(alertActions.error(registerData?.status?.message));
    }
  }, [registerData]);

  function changeMobileNoHandler(e) {
    setMobileNo(e.target.value);
  }
  function changeNameHandler(e) {
    if (/^[a-zA-Z ]*$/.test(e.target.value)) {
      setName(e.target.value);
    } else if (!e.target.value) {
      setName("");
    }
  }

  function changeEmailHandler(e) {
    setEmail(e.target.value);
  }
  function changePasswordHandler(e) {
    setPassword(e.target.value);
  }
  function changeRePasswordHandler(e) {
    setRePassword(e.target.value);
  }

  function handleSubmit(e) {
    e.preventDefault();
    setSubmitted(true);
    var filter = /^[0-9]{10}$/;
    var pattern =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (name && mobile_no && email && password) {
      if (email && !pattern.test(email)) {
        dispatch(alertActions.error("Enter Valid Email Address"));
        // setTimeout(() => dispatch(alertActions.clear()), 3000);
      } else if (mobile_no && !filter.test(mobile_no)) {
        dispatch(alertActions.error("Enter Valid Mobile Number"));
        // setTimeout(() => dispatch(alertActions.clear()), 3000);
      } else if (password !== re_password) {
        dispatch(alertActions.error("Both Password Must Match"));
        // setTimeout(() => dispatch(alertActions.clear()), 3000);
      } else {
        dispatch(registration(name, email, mobile_no, password))
          .then((response) => {
            localStorage.setItem("expiresIn", response?.data?.data?.expires_in);
            var today = new Date();
            localStorage.setItem("loginTime", today);
            setRegisterData(response?.data);
          })
          .catch((error) => {
            console.log("Errorreg", error);
          });
      }
    }
  }

  return {
    name,
    email,
    mobile_no,
    password,
    re_password,
    changeNameHandler,
    changeEmailHandler,
    changeMobileNoHandler,
    changePasswordHandler,
    changeRePasswordHandler,
    submitted,
    handleSubmit,
  };
};
export default useRegistrationHooks;
