import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { alertActions } from "../../../actions";
import { UserService } from "../../../services";
import { useNavigate } from "react-router-dom";

const useCheckout = () => {
  let history = useNavigate();
  const [checkoutRes, setCheckoutRes] = useState({});
  const [purpose, setPurpose] = useState("");
  const [cart, setCart] = useState();
  const [cartData, setCartData] = useState([]);
  const dispatch = useDispatch();
  const [arrData, srtArrData] = useState([]);
  const [amount, setAmount] = useState(0);
  let dataSum = 0;
  let dataTotal = 1;
  const arr = [];
  let gtotal = 0;
  const [totalcount, setTotalCount] = useState(0);
  const [gstamount, setGstAmount] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [gtotals, setgtotal] = useState(0);


  useEffect(() => {
    if (checkoutRes?.data?.status?.code === 0) {
      dispatch(alertActions.success(checkoutRes?.data?.status?.message));
    } else {
      dispatch(alertActions.error(checkoutRes?.data?.status?.message));
    }
    UserService.getCart()
      .then((response) => {
        setCartData(response?.data?.data);

        response.data.data.result.map((res) => {
          arr.push(res._id)
          if (res.category === "image") {
            dataSum = parseFloat(dataSum) + parseFloat(res.image[0].price);
          }
          else if (res.category === "audio") {
            dataSum = parseFloat(dataSum) + parseFloat(res.audio[0].price);
          } else {
            dataSum = parseFloat(dataSum) + parseFloat(res.video[0].price);
          }
        }

        )
        
        // console.log(dataSum + "hhhhhhhhhhhhhhh");
        // console.log(response.data.data.);
        if (response.data.data.count === 0) {
          history("/");
        }


        setTotalPrice(dataTotal);
        setGstAmount(response.data.data.tax)
        setTotalCount(response.data.data.count);
        setAmount(dataSum);
        srtArrData(arr);
        dataTotal = (parseFloat(gstamount) * parseFloat(amount)) / 100;
        gtotal = parseFloat(dataSum) + (parseFloat(response.data.data.tax) * parseFloat(dataSum)) / 100;
        setgtotal(gtotal)
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  }, [checkoutRes]);

  //get cart
  function handleGetCart() {
    UserService.getCart()
      .then((response) => {
        setCart(response?.data?.data?.count);

      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  }

  //radio button
  const changeCtypeHandler = (e) => {
    // console.log(e.target.value);
    setPurpose(e.target.value);
  }

  //handleCheckout
  function handleCheckoutItem(singleProduct) {
    if (purpose === "") {
      dispatch(alertActions.error("Please Select The Purpose"));
    } else {
      UserService.checkoutCartItem(arrData, gtotals, purpose, singleProduct).then((res) => {

        if (res.data.status.code === 0) {
          dispatch(alertActions.success(res?.data?.status?.message));
          history("/Thankyou");
        } else {
          dispatch(alertActions.error(res?.data?.status?.message));
        }
      })
        .catch((error) => {
          console.log("Errorreg", error);
        });

      // UserService.checkoutCart(_id, quantity, purpose)
      //   .then((response) => {
      //     setCheckoutRes(response);
      //     handleGetCart();
      //     history("/Thankyou");
      //   })
      //   .catch((error) => {
      //     console.log("Errorreg", error);
      //   });
    }
  }

  return { handleCheckoutItem, cart, changeCtypeHandler, purpose, cartData, totalcount, amount, gstamount, totalPrice };
};
export default useCheckout;
