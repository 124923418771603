import "./App.css";
import { Route, Routes, Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Provider } from "react-redux";
import store from "./store";
import { useEffect } from "react";
import { alertActions } from "./actions/alert.actions";
import HomePage from "./component/pages/RrcHomeScreen/home";
import Login from "./component/pages/RRCLoginScreen/Login";
import Registration from "./component/pages/RRCRegistrationScreen/Registration";
import List from "./component/pages/RRCListScreen/List";
import ForgotPassword from "./component/pages/RRCForgotScreen/ForgotPassword";
import ConfirmPassword from "./component/pages/RRCConfirmPasswordScreen/ConfirmPassword";
import ImageListPage from "./component/pages/RRCImageListScreen/ImageList";
import Footer from "./component/pages/RRCFooter";
import GetCart from "./component/pages/RRCGetCartScreen/GetCart";
import Events from "./component/pages/RrcEventScreen/Event";
import Blogs from "./component/pages/RrcBlogScreen/Blog";
import Checkout from "./component/pages/RrcCheckoutScreen/checkout";
import Thankyou from "./component/pages/RRCThankYou/thankyou";
import ContactUs from "./component/pages/RRCContactUs/ContactUs";
import MyAccount from "./component/pages/RrcMyAccount/MyAccount";

//App

const Main = () => {
  const dispatch = useDispatch();

  const alert = useSelector((state) => state?.alert);
  const user = JSON.parse(localStorage.getItem("state"));
  const isUser = user ? Object.keys(user.userData).length : 0;

  useEffect(() => {
    setTimeout(() => dispatch(alertActions.clear()), 3000);
  }, [alert?.message]);

  //time calculation
  var today = new Date();
  const presentTime = today;

  const expiresIn = localStorage.getItem("expiresIn");
  const loginTime = localStorage.getItem("loginTime");
  const diffTime = Date.parse(presentTime) - Date.parse(loginTime);

  //session expired
  let tokenExpired = false;
  if (Number(expiresIn) <= Number(diffTime)) {
    tokenExpired = true;
  }

  return (
    <div>
      {alert?.message && (
        <div className={`alert ${alert?.type}`}>{alert?.message}</div>
      )}

      <Routes>
        <Route
          path="/login"
          element={isUser && !tokenExpired ? <Navigate to="/" /> : <Login />}
        />
        <Route
          path="/registration"
          element={isUser ? <Navigate to="/" /> : <Registration />}
        />

        <Route path="/" element={<HomePage />} />
        <Route
          path="/list"
          element={isUser ? <List tokenExpired={tokenExpired} /> : <Login />}
        />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/confirm-password/:id" element={<ConfirmPassword />} />
        <Route
          path="/getCart"
          element={isUser ? <GetCart tokenExpired={tokenExpired} /> : <Login />}
        />
        <Route
          path="/gallery"
          element={
            isUser ? <ImageListPage tokenExpired={tokenExpired} /> : <Login />
          }
        />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/events" element={<Events />} />
        <Route
          path="/checkout"
          element={
            isUser ? <Checkout tokenExpired={tokenExpired} /> : <Login />
          }
        />

        <Route
          path="/MyAccount"
          element={
            isUser ? <MyAccount tokenExpired={tokenExpired} /> : <Login />
          }
        />

        <Route path="/contact" element={<ContactUs />} />
        <Route path="/thankyou" element={<Thankyou />} />
        <Route path="/" element={<HomePage />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </div>
  );
};

export default function App() {
  return (
    <Provider store={store}>
      <Main />
      <Footer />
    </Provider>
  );
}
